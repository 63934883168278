import { Button } from '../../ui/button/button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { Input } from '../../ui/input/input';
import { LogDecorator } from '@tenryu/log-decorator';
import './login.scss';
import { apiActions } from '../../../slices/api-slice';
import loginBg from '../../../assets/img/bg/login_bg.jpg';
import loginLogo from '../../../assets/img/logo/ppmcloud_03_t.png';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { ResponseBase } from '../../../api/response-base';
import iconEyeOn from '../../../assets/img/icon/eye_on.svg';
import iconEyeOff from '../../../assets/img/icon/eye_off.svg';
import chromeIcon from '../../../assets/img/icon/googlechrome_icon.svg';
import { commonActions } from '../../../slices/common-slice';
import { ApiBaseBack } from '../../../api/back/api-base-back';
import { SessionTokenManager } from '../../../manager/session-token-manager';
import { ApiBaseFront } from '../../../api/front/api-base-front';
import { TokenPostForStaffResponse } from '../../../api/front/token/api-token';
import { localStorageActions } from '../../../slices/local-storage-slice';
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';
import { ForgetPasswordDialog } from '../../ui/forget-password-dialog/forget-password-dialog';
import { Env } from '../../../models/env';

// - ツールチップ表示するもの -
type InputErrorItem = 'store' | 'password' | 'staffId' | 'none';
const SHOP_PASSWORD_LENGTH = 7;

export const StaffLogin = () => {

  // - 半角数値正規表現 -
  const IntegerRegExp = useRef(/^[0-9]+/).current;
  // - 半角英数字記号正規表現 -
  const halfWidthStringExp = useRef(/^[!-~]+$/).current;
// - 大文字小文字半角英数字正規表現 -
  const halfWidthAlphanumericRegExp = useRef(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]+$/).current;
// - バリデーションチェック -
  const checkInputValidation = useCallback((type: InputErrorItem, inputItem: string): boolean  => {
    let result: boolean;
    switch (type) {
      case 'store':
        result = !!(inputItem && inputItem.match(IntegerRegExp) && inputItem.length === 7);
        break;
      case 'staffId':
        result = !!(inputItem && (inputItem.match(halfWidthStringExp) && inputItem.length >= 6));
        break;
      case 'password':
        result = !!(inputItem && (inputItem.match(halfWidthAlphanumericRegExp) && inputItem.length < 16));
        break;
      default:
        result = true;
        break;
    }
    return result;
  }, []);

  // - Effect -
  // -- ログまとめ --
  useEffect(() => {
    LogDecorator.group('<red>ログイン</red>');
    return () => LogDecorator.groupEnd();
  }, []);
  const { kijshopCdInitialValue, dialogs, isChrome, loginMode, isRedirect } = useAppSelector((state) => ({
    kijshopCdInitialValue: state.storage.staffInfo.kijshopCd,
    dialogs: state.dialog.dialogs,
    isChrome: state.common.isChrome,
    loginMode: state.storage.loginMode,
    isRedirect: state.common.isRedirect,
  }), lodash.isEqual);
  // - Hooks -
  const dispatch = useDispatch();
  // - state -
  // -- 店舗ID --
  const [store, setStore] = useState(kijshopCdInitialValue);
  // -- スタッフID --
  const [staffId, setStaffId] = useState('');
  // -- パスワード --
  const [password, setPassword] = useState('');
  // -- パスワード表示有無 --
  const [isDisplayPass, setIsDisplayPass] = useState(false);
  // -- xmlバージョンリクエスト情報 --
  const [xmlVersion, setXmlVersion] = useState('');
  const [xml, setXml] = useState('');
  const [version, setVersion] = useState('');
  // -- 店舗コードがバリデーションにかかっているか --
  const [isStoreError, setIsStoreError] = useState(false);
  // -- スタッフIDがバリデーションにかかっているか --
  const [isStaffIdError, setIsStaffIdError] = useState(false);
  // -- パスワードがバリデーションにかかっているか --
  const [isPasswordError, setIsPasswordError] = useState(false);
  // -- ツールチップ表示するもの --
  const [tooltipItem, setTooltipItem] = useState<InputErrorItem>('none');
  // -- パスワードが店舗パスワードの長さと一緒かどうか --
  const [isPasswordShopLength, setIsPasswordShopLength] = useState(false);
  // - Callback -
  // -- 店舗ID --
  const handlerChangeStore = useCallback((v) => {
    setStore(v);
  }, []);
  // -- スタッフID --
  const handlerChangeStaffId = useCallback((v) => {
    setStaffId(v);
  }, []);
  // -- パスワード --
  const handlerChangePassword = useCallback((v) => {
    setPassword(v);
  }, []);
  // -- ログイン --
  const handlerClickLogin = useCallback(() => {
    console.log('login check...');
    if (!store || !password || !staffId) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '未入力の項目があります。',
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              console.log('login success!!');
              setIsStoreError(!checkInputValidation('store', store));
              setIsStaffIdError(!checkInputValidation('staffId', staffId));
              setIsPasswordError(!checkInputValidation('password', password));
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      return;
    }
    dispatch(apiActions.staffLogin(
      {
        kijshopCd: store,
        staffId,
        password,
      },
      {
        onSuccess: (res: ResponseBase<TokenPostForStaffResponse>) => {
          if(!res.body.data) return;
          const sessionToken = res.body.data.sessionId || '';
          const fSessionToken = res.body.data.token || '';
          ApiBaseBack.session = sessionToken;
          ApiBaseFront.session = fSessionToken;
          new SessionTokenManager().save(sessionToken, fSessionToken);
          // dispatch(commonActions.setKijshopCd(res.body.data?.[0]?.kijshopCd || ''));
          dispatch(commonActions.getData(store));
          dispatch(localStorageActions.getLocalData(store));
          dispatch(localStorageActions.setData(store, 'shopName', res.body.data?.shopName || ''));
          dispatch(localStorageActions.setLoginMode('STAFF'));
          dispatch(apiActions.versionCheck(
            {
              xmlVersion,
              xml,
              version,
            },
            {
              onSuccess: () => {
                if(!res.body.data) return;
                if(!res.body.data.status) {
                  dispatch(push(RoutingPath.cnv.staffPasswordRegister({ kijshopCd: store })));
                  return;
                }
                dispatch(commonActions.getData(store));
                dispatch(localStorageActions.getLocalData(store));
                dispatch(localStorageActions.setMachineId(staffId));
                dispatch(push(RoutingPath.cnv.orders({ kijshopCd: store })));
              },
            },
          ));
        },
        onBasicError: (e) => {
          setIsStoreError(!checkInputValidation('store', store));
          setIsStaffIdError(!checkInputValidation('staffId', staffId));
          setIsPasswordError(!checkInputValidation('password', password));
          dispatch(dialogActions.pushMessage({
            // title: '警告',
            // message: ['店舗IDもしくはパスワードが間違っています'],
            title: '確認',
            message: e.messages,
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              },
            ],
          }));
        },
      },
    ));
  }, [store, password, staffId, checkInputValidation]);
  // -- パスワード表示有無 --
  const handlerClickDisplayPassword = useCallback(() => {
    if (!isChrome) return;
    setIsDisplayPass(prev => !prev);
  }, [isDisplayPass, isChrome]);
  const handlerClickForgetPass = useCallback(() => {
    dispatch(dialogActions.push({
      title: 'パスワードの種類を選択してください',
      element: <ForgetPasswordDialog />,
    }));
  }, []);
  const handlerClickAdminLogin = useCallback(() => {
    if (isChrome) {
      dispatch(push(RoutingPath.login));
    }
  }, [isChrome]);
  const handlerMouseOver = useCallback((type: InputErrorItem) => {
    setTooltipItem(type);
  }, []);
  // -- パスワードがSHOP_PASSWORD_LENGTHと一緒かどうか --
  const handlerBlurPassword = useCallback(() => {
    if (password.length === SHOP_PASSWORD_LENGTH) {
      setIsPasswordShopLength(true);
    } else {
      setIsPasswordShopLength(false);
    }
  }, [password]);

  useEffect(() => {
    if (!dialogs.length && (ApiBaseBack.session || ApiBaseFront.session)) {
      new SessionTokenManager().save('', '');
      window.location.reload();
    }
    dispatch(commonActions.checkBrowser());
    // MEMO 9/8のMTGでリダイレクトはしないことになったのでコメントアウト
    // if (!isRedirect) {
    //   if (loginMode === 'ADMIN') {
    //     dispatch(push(RoutingPath.login));
    //   } else {
    //     dispatch(push(RoutingPath.staffLogin));
    //   }
    //   dispatch(commonActions.setIsRedirect(true));
    // }
    // new SessionTokenManager().save('sessionToken', fSessionToken);
  }, []);


  return (
    <div className="login">
      <div className="login__inner">
        <div className="login__col2">
          <div className="login__col2__inner">
            <h1 className="login__col2__inner__header">
              スタッフIDでログイン
            </h1>
            <div className="login__col2__inner__contents">
              <Input
                value={store}
                onChange={(e) => handlerChangeStore(e.target.value)}
                handlerEnter={handlerClickLogin}
                placeholder="店舗ID"
                uiSize="lg"
                maxLength={7}
                onMouseOver={() => handlerMouseOver('store')}
                onMouseLeave={() => handlerMouseOver('none')}
                error={isStoreError}
                tooltip={isStoreError && tooltipItem === 'store' ? { messages: ['半角数値7桁'] } : undefined}
                disabled={!isChrome}
              />
              <Input
                value={staffId}
                onChange={(e) => {
                  handlerChangeStaffId(e.target.value);
                }}
                placeholder="スタッフID"
                uiSize="lg"
                onMouseOver={() => handlerMouseOver('staffId')}
                onMouseLeave={() => handlerMouseOver('none')}
                error={isStaffIdError}
                tooltip={isStaffIdError && tooltipItem === 'staffId' ?
                  { messages: ['半角6桁以上'] } : undefined}
                disabled={!isChrome}
              />
              <Input
                type={isDisplayPass ? '' : 'password'}
                value={password}
                onChange={(e) => {
                  handlerChangePassword(e.target.value);
                }}
                handlerEnter={handlerClickLogin}
                placeholder="パスワード"
                uiSize="lg"
                maxLength={15}
                onMouseOver={() => handlerMouseOver('password')}
                onMouseLeave={() => handlerMouseOver('none')}
                error={isPasswordError}
                tooltip={isPasswordError && tooltipItem === 'password' ?
                  { messages: ['大文字小文字を混ぜた半角英数字16字未満'] } : undefined}
                disabled={!isChrome}
                onBlur={() => handlerBlurPassword()}
              />
              {isPasswordShopLength &&
                <div
                  style={
                    {
                      color: 'red',
                      fontSize: '12px',
                      marginTop: '2px',
                    }
                  }
                >店舗パスワードを入力していませんか？</div>
              }
              <div
                className="login__pass_display"
                onClick={handlerClickDisplayPassword}
              >
                <img
                  src={isDisplayPass ? iconEyeOff : iconEyeOn}
                  alt=""
                />
                <span>{isDisplayPass ? 'パスワードを非表示にする' : 'パスワードを表示する'}</span>
              </div>
              <Button
                label="ログイン"
                onClick={handlerClickLogin}
                // disabled={!store || !password}
                disabled={!isChrome}
                color="blue_cyan"
                size="lg"
              />
              <p
                className="login__pass_remember"
                onClick={handlerClickForgetPass}
              >
                パスワードをお忘れの方はこちら
              </p>
            </div>
            <div className={'chrome__guide'}>
              <p>● PPMクラウドの推奨利用環境について</p>
              <p>&emsp;・動作対象ブラウザは【 Google Chrome 】です</p>
              <p>&emsp;&emsp;それ以外のブラウザでは、ご利用いただけません</p>
              <div className={'chrome__guide__link'}>
                <p>{'>'}&emsp;
                  <a href="https://support.google.com/chrome/answer/95346?hl=ja&co=GENIE.Platform%3DDesktop#zippy=">
                    Google Chromeの導入については、こちら
                  </a>
                </p>
                <img
                  src={chromeIcon}
                  alt=""
                  width={40}
                  height={40}
                />
              </div>
            </div>
            <div className={'version version__staff'}>{Env.version ? `ver${Env.version}` : ''}</div>
          </div>
        </div>
        <div className="login__col1">
          <img
            src={loginBg}
            alt=""
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          /> {/* リロード時対策でインライン指定 */}
          <img
            src={loginLogo}
            alt=""
          />
          <div className={'switch_login'}>
            <Button
              color={'warning'}
              label="店舗IDでログインする"
              onClick={handlerClickAdminLogin}
              size={'lg'}
            />
          </div>
          <div className={'copywriter'}>&copy; LABONETWORK Co.,Ltd.</div>
        </div>
      </div>
    </div>
  );
};
