import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { UiManager } from '../ui/ui.manager';
import { LayoutDataManager } from '../data/layout-data.manager';
import { CjContainer } from '../../model/cj-factory';
import { CjTool } from '../../../utilities/cj-tool';
import { dialogActions } from '../../../components/dialog/slice/dialog-slice';
import { store } from '../../../app/store';
import { XmlStructureModel, XmlStructureOrderPartsData } from '../../../xml/model/xml-structure-model';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { TOrderPageDataViewModel } from '../../../xml/model/order/xml-order-page-data-model';
import { EditableImageManager } from '../editable-image/editable-image.manager';
import { cloneDeep } from 'lodash';
import { EditableImage } from '../image-edit/editable-image';
import { AlbumPage } from '../../albam/albam';
import { OrderPageDataXml } from '../../../xml/class/order/order-page-data-xml';
import { SelectIdQueueManager } from '../select-id-queue/selectIdQueueManager';

export type CheckFinishType = {
  xml: XmlStructureModel,
  kijshopCd: string,
  shopOrderId: string,
  orderId: string,
  totalPage: number,
  startDate: string,
  pageCount: number,
}

export class ValidationManager implements ILayoutEditorManagerBase {

  private uiManager!: UiManager;
  private layoutDataManager!: LayoutDataManager;
  private editableImageManager!: EditableImageManager;

  initialize() {
    this.uiManager.on('r->l:valid:save-layout', (v) => this.checkSave(v.xml, v.kijshopCd, v.shopOrderId, v.orderId, v.startDate));
    this.uiManager.on('r->l:valid:complete-layout', (v) => this.checkCompleted(v.xml, v.kijshopCd, v.shopOrderId, v.orderId, v.startDate));
    this.uiManager.on('r->l:close:change-page-num', (v) => this.checkCompleted(v.data.xml, v.data.kijshopCd, v.data.shopOrderId, v.data.orderId, v.data.startDate));
  }

  destroy() {
    this.uiManager.off('r->l:valid:save-layout');
    this.uiManager.off('r->l:valid:complete-layout');
    this.uiManager.off('r->l:close:change-page-num');
  }

  di(
    uiMng: UiManager,
    layoutDataMng: LayoutDataManager,
    editableImgMng: EditableImageManager,
  ) {
    this.uiManager = uiMng;
    this.layoutDataManager = layoutDataMng;
    this.editableImageManager = editableImgMng;
  }

  checkUpload(kijshopCd: string, shopOrderId: string) {
    return new Promise<Boolean>((resolve) => {
      const checkList = this.editableImageManager.orderCheck(kijshopCd, shopOrderId);
      const notUploadedCount = checkList.yet;
      if (notUploadedCount) {
        store.dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            `アップロード中の画像が${notUploadedCount}枚あります。`,
            `時間を空けて再度、保存してください。`,
          ],
          buttons: [
            {
              label: 'OK',
              callback: () => {
                store.dispatch(dialogActions.pop());
                resolve(false);
              },
            },
          ],
        }));
      } else {
        resolve(true);
      }
    });
  }

  checkRegisteredSelectId() {
    return new Promise<Boolean>((resolve) => {
      const unRegisteredIdCount = SelectIdQueueManager.ins.getUnRegisteredCount;
      if (unRegisteredIdCount) {
        store.dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            `アップロード処理が完了していない画像が${unRegisteredIdCount}枚あります。`,
            `時間を空けて再度、保存してください。`,
          ],
          buttons: [
            {
              label: 'OK',
              callback: () => {
                store.dispatch(dialogActions.pop());
                resolve(false);
              },
            },
          ],
        }));
      } else {
        resolve(true);
      }
    });
  }

  private async showAdditionalFrame() {
    return new Promise<void>((resolve) => {
      const isAdditional = UiManager.ins.eventNames().includes('r->l:transparent-additional');
      if (isAdditional) {
        UiManager.ins.emit('r->l:transparent-additional', {
          type: 'show', callback: () => {
            resolve();
          },
        });
      } else {
        resolve();
      }
    });
  }

  // - 一時保存時のチェック -
  async checkSave(xml: XmlStructureModel, kijshopCd: string, shopOrderId: string, orderId: string, startDate: string) {
    const isUpload = await this.checkUpload(kijshopCd, shopOrderId);
    if (!isUpload) return;
    const isRegisteredSelectId = await this.checkRegisteredSelectId();
    if (!isRegisteredSelectId) return;
    const settingData = store.getState().layout.albumSettingData;
    const {
      pageCount,
      productName,
    } = {
      pageCount: settingData.pageCount,
      productName: settingData.productName,
    };
    const album = store.getState().layout.albumPages;
    const tempGroupName = store.getState().layout.templateGroupName;
    const totalPageNum = [...album.top.albums, ...album.endCover.albums].length + (album.page.albums.length * (tempGroupName === 'DAI' ? 1 : album.page.pageCount));
    // -- 判定 --
    if (totalPageNum > Number(pageCount)) {
      store.dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          'レイアウト済みのページ数がご指定のページ数よりも多いため、保存できません。',
          ' ページ数を変更した後に保存して下さい。',
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => store.dispatch(dialogActions.pop()),
          },
        ],
      }));
    } else {
      // UiManager.ins.emit('r->l:transparent-additional', {
      //   type: 'show', callback: () => {
      await this.showAdditionalFrame();
      // --- xml生成確認ダイアログ表示 ---
      store.dispatch(dialogActions.pushMessage({
        title: '書き出し:最終確認',
        message: [
          `商品名:${productName}`,
          'で書き出しを行います。',
        ],
        buttons: [
          {
            label: 'いいえ',
            callback: () => {
              store.dispatch(dialogActions.pop());
            },
          },
          {
            label: 'はい',
            callback: () => {
              this.uiManager.emit('l->r:wait-loading', { message: '書き込み中です...' });
              store.dispatch(dialogActions.pop());
              xml && UiManager.ins.emit('r->l:save-layout', {
                xml, kijshopCd, shopOrderId, orderId, startDate, callback: () => {
                  store.dispatch(push(RoutingPath.cnv.ordersPreparation({ kijshopCd, shopOrderId })));
                  this.uiManager.emit('l->r:wait-loading', { message: '' });
                },
              });
            },
          },
        ],
      }));
      //   },
      // });
    }
  }

  // - ページ数チェック -
  checkPageCount(xml: XmlStructureModel, kijshopCd: string, shopOrderId: string, orderId: string, startDate: string) {
    // -- ページ数を取得 --
    const albumSetting = store.getState().layout.albumSettingData;
    const settingPage = albumSetting.pageCount;
    const album = store.getState().layout.albumPages;

    // const tempGroupName = store.getState().layout.templateGroupName;
    // const totalPageNum = [...album.top.albums, ...album.endCover.albums].length + (album.page.albums.length * (tempGroupName === 'DAI' ? 1 : 2));
    const _album = {
      top: album.top,
      page: album.page,
      endCover: album.endCover,
    };
    const totalPageNum = Object.values(_album).map((v) => v.albums.length * v.pageCount).reduce((a, b) => a + b);
    // const total = Object.values(album).map((v) => v.albums.length * v.pageCount);
    const minPageCount = albumSetting.pageCountInfo.min;
    const maxPageCount = albumSetting.pageCountInfo.max;
    const pageCount = Number(album.page.pageCount);
    return new Promise<Boolean>((resolve) => {
      if (minPageCount && totalPageNum < Number(minPageCount)) {
        store.dispatch(dialogActions.pushMessage({
          title: '書き出しエラー',
          message: [
            `利用できる最小ページ数を満たしていません。`,
            `最小ページ数=${minPageCount}, 現在のページ数=${totalPageNum}`,
          ],
          buttons: [
            {
              label: 'はい',
              callback: () => {
                store.dispatch(dialogActions.pop());
                resolve(false);
              },
            },
          ],
        }));
      } else if (totalPageNum > Number(settingPage) || totalPageNum < Number(settingPage)) {
        // page x 1あたりの実際のページ数が複数かどうか(1-2など)
        const isMulchPage = (() => {
          if (album.page.albums.length) {
            return album.page.albums[0].pageNo.includes('-')
          } else {
            return false
          }
        })()
        const num = isMulchPage ? (Number(settingPage) - totalPageNum) / Number(albumSetting.stepPageCount) : (Number(settingPage) - totalPageNum);
        if (Number(maxPageCount) < Number(totalPageNum)) {
          store.dispatch(dialogActions.pushMessage({
            title: '書き出しエラー',
            message: [
              '利用できる最大ページ数を超えています。',
              `最大ページ数=${maxPageCount}, 現在のページ数=${totalPageNum}`,
            ],
            buttons: [
              { label: 'OK', callback: () => store.dispatch(dialogActions.pop())},
            ]
          }))
          return;
        }
        store.dispatch(dialogActions.pushMessage({
          title: '確認',
          message: totalPageNum < Number(settingPage) ?
            [
              `page x ${num}が足りません。 `,
              `指定されたページ数は${settingPage}ページですが、現在${totalPageNum}ページあります。`,
              ` ページ数の変更を行いますか？`,
            ]
            : [
              `指定されたページ数は${settingPage}ページですが、現在${totalPageNum}ページあります。`,
              ` ページ数の変更を行いますか？`,
            ],
          buttons: [
            {
              label: 'いいえ',
              callback: () => {
                store.dispatch(dialogActions.pop());
                store.dispatch(dialogActions.pushMessage({
                  title: '確認',
                  message: [
                    `${settingPage}ページまでページを変更してから、`,
                    `再度実行して下さい。`,
                  ],
                  buttons: [
                    {
                      label: 'OK',
                      callback: () => {
                        store.dispatch(dialogActions.pop());
                        resolve(false);
                      },
                    },
                  ],
                }));
              },
            },
            {
              label: 'はい',
              callback: () => {
                store.dispatch(dialogActions.pop());
                this.uiManager.emit('l->r:open:change-page-num', {
                  data: { xml, kijshopCd, shopOrderId, orderId, totalPage: totalPageNum, startDate, pageCount },
                  albumSetting,
                  callback: () => {
                    resolve(false)
                  }
                });
              },
            },
          ],
        }));
      } else {
        resolve(true);
      }
    });
  }

  // - 画像の枚数下限チェック -
  checkMinImageCount(partsData: XmlStructureOrderPartsData[], photoCount: number) {
    let totalCount = 0;
    return new Promise<Boolean>((resolve) => {
      let minImageCount: number = 1;
      let minImageAdd: number = 0;
      let pageCount: number = 0;
      for (const parts of partsData) {
        const view = cloneDeep(parts.xml.viewModel);
        if (Number(view.pageCount)) {
          pageCount = Number(view.pageCount);
        }
        if (Number(view.minPageImageCount)) {
          minImageCount = Number(view.minPageImageCount);
        }
        if (Number(view.minImageAdd)) {
          minImageAdd = Number(view.minImageAdd ?? 0);
        }
      }
      totalCount = Math.floor(pageCount * minImageCount + (minImageAdd ?? 0));
      if (photoCount < totalCount) {
        store.dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            `写真数が枚数下限を下回っています。`,
            `下限枚数 = ${totalCount}、使用写真枚数 = ${photoCount}。`,
          ],
          buttons: [
            {
              label: 'OK',
              callback: () => {
                store.dispatch(dialogActions.pop());
                resolve(false);
              },
            },
          ],
        }));
      } else {
        resolve(true);
      }
    });
  }

  // - 画像の枚数上限チェック -
  checkMaxImageCount(partsData: XmlStructureOrderPartsData[], photoCount: number) {
    let totalCount = 0;
    return new Promise<Boolean>((resolve) => {
      let pageImageCount = 1;
      let imageAdd = 0;
      let pageCount = 0;

      for (const parts of partsData) {
        const view = parts.xml.viewModel;
        if (Number(view.pageCount)) {
          pageCount = Number(view.pageCount);
        }
        if (Number(view.maxPageImageCount)) {
          pageImageCount = Number(view.maxPageImageCount);
        }
        ;
        if (Number(view.maxImageAdd)) {
          imageAdd = Number(view.maxImageAdd);
        }
      }
      totalCount = Math.ceil(pageCount * pageImageCount + imageAdd);
      if (photoCount > totalCount) {
        store.dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            `写真数が枚数上限を超えています。`,
            `上限枚数 = ${totalCount}、使用写真枚数 = ${photoCount}。`,
          ],
          buttons: [
            {
              label: 'OK',
              callback: () => {
                store.dispatch(dialogActions.pop());
                resolve(false);
              },
            },
          ],
        }));
      } else {
        resolve(true);
      }
    });
  }

  async checkOptionPageType(options: {name: string, xml: OrderPageDataXml, album: AlbumPage | null}[]) {
    const optionPages: {[key: string]: number} = {};
    for (const option of options) {
      if (optionPages[option.name]) {
        optionPages[option.name] += 1;
      } else {
        optionPages[option.name] = 1;
      }
    }
    return optionPages;
  }

  // - ページ種チェック -
  async checkPageType() {
    const album = store.getState().layout.albumPages;
    const _optionPages = album.option.filter(v => !v.album)
    const _opPrintPages = album.opPrint.filter(v => !v.album)
    const optionPages = await this.checkOptionPageType(_optionPages)
    const opPrintPages = await this.checkOptionPageType(_opPrintPages)
    const pages = {
      cover: album.cover.page - album.cover.albums.length,
      top: album.top.page - album.top.albums.length,
      end: album.endCover.page - album.endCover.albums.length,
    };
    return new Promise<Boolean>((resolve) => {
      let message = '';
      for (const [key, value] of Object.entries(pages)) {
        if (value <= 0) continue;
        message += `${key} x ${value} / `;
      }
      for (const [key, value] of Object.entries(optionPages)) {
        if (value <= 0) continue;
        message += `${key} x ${value} / `;
      }
      for (const [key, value] of Object.entries(opPrintPages)) {
        if (value <= 0) continue;
        message += `${key} x ${value} / `;
      }
      message = message.slice(0, -2);
      if (message) {
        store.dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            `${message}が足りません。`,
            `指定のページ種を追加してレイアウトして下さい。`,
          ],
          buttons: [
            {
              label: 'OK',
              callback: () => {
                store.dispatch(dialogActions.pop());
                resolve(false);
              },
            },
          ],
        }));
      } else if (
        !album.cover.albums.length
        && !album.top.albums.length
        && !album.page.albums.length
        && !album.endCover.albums.length
      ) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }

  checkPhotoInFrame(partsData: XmlStructureOrderPartsData[]) {
    const album = store.getState().layout.albumPages;
    const editors: CjContainer[] = this.layoutDataManager.selector('getAlbum');
    if (!editors.length) throw new Error('edit data not found !!');
    return new Promise<CjContainer[]>((resolve, reject) => {
      const unPhotoList: CjContainer[] = [];
      const _badPages: any[] = [];
      const messages = [];
      for (const page of editors) {
        const isLogo = CjTool.getImageAreaContainer(page).logoFlag;
        const isDelete = CjTool.getImageAreaContainer(page).deleteFlag;
        if (!CjTool.checkPhotoInFrame(page) && !isLogo && !isDelete) {
          page.deleteFlag = true;
          unPhotoList.push(page);
          _badPages.push(page.pageId);
        }
      }
      const badPages = _badPages.filter((v, i, self) => {
        return self.indexOf(v) === i;
      });
      const pageData: Partial<TOrderPageDataViewModel>[] = [];
      for (const v of partsData) {
        if (!v || !v.page || !v.page.pageData) continue;
        for (const vv of v.page?.pageData) {
          pageData.push(vv.viewModel);
        }
      }
      for (const badPage of badPages) {
        const cover = album.cover.albums.find(elm => elm.id === badPage);
        const top = album.top.albums.find(elm => elm.id === badPage);
        const page = album.page.albums.find(elm => elm.id === badPage);
        const end = album.endCover.albums.find(elm => elm.id === badPage);
        /* layoutDataManager に削除したアルバムが残るのでオプション商品の場合も存在するか確認する */
        const option = album.option.find(elm => elm.album?.id === badPage);
        let badAlbum: Partial<TOrderPageDataViewModel> | undefined;
        let message = '';
        switch (true) {
          case Boolean(cover):
            badAlbum = pageData.find(elm => elm.pageType === cover!.typeID);
            message = `・ページ種: ${badAlbum?.displayPageType ?? '表紙'}`;
            break;
          case Boolean(top):
            badAlbum = pageData.find(elm => elm.pageType === top!.typeID);
            message = `・ページ種: ${badAlbum?.displayPageType ?? '頁-TOP'}`;
            break;
          case Boolean(page):
            badAlbum = pageData.find(elm => elm.pageType === page!.typeID);
            message = `・ページ種: ${badAlbum?.displayPageType ?? '頁'} ページ: ${page!.pageNo ?? '-'}`;
            break;
          case Boolean(end):
            badAlbum = pageData.find(elm => elm.pageType === end!.typeID);
            message = `・ページ種: ${badAlbum?.displayPageType ?? '頁-END'}`;
            break;
          case Boolean(option):
            message = `・ページ種: ${badAlbum?.displayPageType ?? 'その他'}`;
            break;
        }
        if (message) {
          messages.push(message);
        }
      }
      if (unPhotoList.length && messages.length) {
        messages.unshift(``);
        messages.unshift(`写真フレームを削除してレイアウトを完了しますか？`);
        messages.unshift(`下記のページの写真フレームに写真が配置されていません。`);
        messages.push(``);
        messages.push(`写真フレームを削除する場合は「はい」`);
        messages.push(`上記のページを再編集する場合は「いいえ」を選択してください。`);
        store.dispatch(dialogActions.pushMessage({
          title: '書き出し:最終確認',
          message: messages,
          buttons: [
            {
              label: 'いいえ',
              callback: () => {
                store.dispatch(dialogActions.pop());
                reject();
              },
            },
            {
              label: 'はい',
              callback: () => {
                store.dispatch(dialogActions.pop());
                resolve(unPhotoList);
              },
            },
          ],
        }));
      } else {
        resolve(unPhotoList);
      }
    });
  }


  // - レイアウト完了時、チェック処理 -
  async checkCompleted(xml: XmlStructureModel, kijshopCd: string, shopOrderId: string, orderId: string, startDate: string) {
    const settingData = store.getState().layout.albumSettingData;
    const {
      pageCount,
      productName,
    } = {
      pageCount: settingData.pageCount,
      productName: settingData.productName,
    };
    const partsData: XmlStructureOrderPartsData[] | undefined = xml.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId)?.parts?.partsData;
    const photoList = this.layoutDataManager.selector('getImages');
    const editableImageList = EditableImageManager.ins.list;
    const useEditableImageList: EditableImage[] = [];
    photoList.forEach((p: any) => editableImageList.forEach((v) => {
      if (v.kind === '6' && v.id === p?.editableImageId && !useEditableImageList.find((e) => e.id === v.id)) {
        useEditableImageList.push(v);
      }
    }));
    const photoCount = (() => {
      let cnt = 0;
      useEditableImageList.forEach((v) => {
        cnt += v.useCount;
      });
      return cnt;
    })();
    if (!partsData) throw new Error('xml not found !!');
    const isUpload = await this.checkUpload(kijshopCd, shopOrderId);
    if (!isUpload) return;
    const isRegisteredSelectId = await this.checkRegisteredSelectId();
    if (!isRegisteredSelectId) return;
    const isPageCount = await this.checkPageCount(xml, kijshopCd, shopOrderId, orderId, startDate).then((v) => v);
    if (!isPageCount) return;
    const isMinCheck = await this.checkMinImageCount(partsData, photoCount).then((v) => v);
    if (!isMinCheck) return;
    const isMaxCheck = await this.checkMaxImageCount(partsData, photoCount).then((v) => v);
    if (!isMaxCheck) return;
    const isTypeCheck = await this.checkPageType().then((v) => v);
    if (!isTypeCheck) return;
    this.checkPhotoInFrame(partsData)
      .then((v) => {
        const visibleList: CjContainer[] = [];
        for (const v2 of v) {
          v2.visible = false;
          // CjTool.checkPhotoInFrame(v2) && (CjTool.getPhotoContainer(v2).deleteFlag = true);
          CjTool.getImageAreaContainer(v2).deleteFlag = true;
          visibleList.push(v2);
        }
        return visibleList;
      })
      .then(async (list) => {
        await this.showAdditionalFrame();
        store.dispatch(dialogActions.pushMessage({
          title: '書き出し:最終確認',
          message: [
            `${productName}`,
            `ページ数:${pageCount}ページ 使用写真枚数:${photoCount}枚で書き出しを行いますか？`,
          ],
          buttons: [
            {
              label: 'いいえ',
              callback: () => {
                for (const v of list) {
                  v.visible = true;
                  // CjTool.checkPhotoInFrame(v) && (CjTool.getPhotoContainer(v).deleteFlag = true);
                  CjTool.getImageAreaContainer(v).deleteFlag = false;
                }
                store.dispatch(dialogActions.pop());
              },
            },
            {
              label: 'はい',
              callback: () => {
                store.dispatch(dialogActions.pop());
                this.uiManager.emit('l->r:wait-loading', { message: '書き込み中です...' });
                xml && UiManager.ins.emit('r->l:complete-layout', {
                  xml, kijshopCd, shopOrderId, orderId, startDate, callback: () => {
                    if (store.getState().layout.isSelectIDError) {
                      window.location.reload();
                    } else {
                      store.dispatch(push(RoutingPath.cnv.ordersPreparation({ kijshopCd, shopOrderId })));
                      this.uiManager.emit('l->r:wait-loading', { message: '' });
                    }
                  },
                });
              },
            },
          ],
        }));
      }).catch(() => {
      return;
    });
  }
}
