import {createSlice} from '@reduxjs/toolkit';
import { ActionType } from '../models/action-type';

// TODO: 冨岡さん　サンプルでログインAPIのレスポンス入れてます(セッションとトークンは別管理なので入れてません)
type StaffSliceState = {
  kijshopCd: string,
  shopName: string,
  status: number,
  userName: string,
  roleType: number,
};

const initialState: StaffSliceState = {
  kijshopCd: '',
  shopName: '',
  status: 0,
  userName: '',
  roleType: 0,
};

const setStaffState: ActionType<StaffSliceState, StaffSliceState> = (state, action) => {
  state = { ...action.payload };
};

export const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    setStaffState,
  },
});

export const staffActions = staffSlice.actions;
export const staffReducer = staffSlice.reducer;
