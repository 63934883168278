import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UiManager } from '../../../../layout-editor/manager/ui/ui.manager';
import { EditableImage } from '../../../../layout-editor/manager/image-edit/editable-image';
import './layout.image-list.scss';
import { Button } from '../../../ui/button/button';
import iconSort from '../../../../assets/img/icon/sort.svg';
import iconTwoCol from '../../../../assets/img/icon/two_col_layout.svg';
import iconOneCol from '../../../../assets/img/icon/one_col_layout.svg';
import { IntersectionObserverFactory, LazyLoadImage } from '../../../ui/image/lazy-load-image';
import iconDnd from '../../../../assets/img/icon/icon_dnd.svg';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../../routes/routing-path';
import { EditableImageManager } from '../../../../layout-editor/manager/editable-image/editable-image.manager';
import { ImageUploadStatus } from '../../../ui/image-upload-status/image-upload-status';
import { StaticTooltip } from '../../../ui/tooltip/static-tooltip';
import { xmlActions } from '../../../../xml/slice/xml-slice';
import { useAppSelector } from '../../../../app/hooks';
import * as lodash from 'lodash';
import {
  RestoreEditableImageManager,
} from '../../../../layout-editor/manager/restore-editable-image/restore-editable-image.manager';
import { apiActions } from '../../../../slices/api-slice';
import { ImageProcessorManager } from '../../../../layout-editor/manager/image-processor/image-processor.manager';
import { WindowsImgBugFix } from '../../../../utilities/windows-img-bug-fix';
import { layoutActions } from '../../../../slices/layout-slice';
import { Image } from '../../../ui/image/image';
import { LoadingCircle } from '../../../ui/loading/loading-circle';
import { store } from '../../../../app/store';
import { LayoutXmlUtile } from '../../../../layout-editor/model/layout.xml.utile';
import { ApiImagesGet } from '../../../../api/front/images/api-images';
import { ApiUploadXml } from '../../../../api/front/xml/api-upload-xml';
import { ApiOrderRegister } from '../../../../api/back/order-register/api-order-register';
import { OrderSelectXml } from '../../../../xml/class/order/order-select-xml';
import { ApiGetXml } from '../../../../api/front/xml/api-get-xml';

type SortType = 'name:up' | 'name:down' | 'date:up' | 'date:down';

type FilterType = 'none' | 'unused';

type Props = {
  unUsedEditableList: string[],
  unUsedPhotoList: any[],
  errorCount: number;
};

type SortMenuProps = {
  onBlur?: () => void,
  sortType: SortType,
  filterType: FilterType,
  callbackSort: (sortType: SortType) => void,
  callbackFilter: (filterType: FilterType, showName?: 'showName') => void,
  showNameType: FilterType,
}
const SortMenu = (props: SortMenuProps) => {
  const {
    onBlur,
    sortType,
    filterType,
    callbackSort,
    callbackFilter,
    showNameType,
  } = props;
  const clickDoc = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target !== _ref.current) {
      onBlur && onBlur();
    }
  };
  // - Ref -
  const _ref = useRef<HTMLDivElement>(null);
  // - Callback -
  const onClickSortType = useCallback((e: React.MouseEvent, sortType: SortType) => {
    e.preventDefault();
    e.stopPropagation();
    callbackSort(sortType);
  }, []);
  const onClickFilterType = useCallback((e: FilterType, showName?: 'showName') => {
    if (e === filterType || e === showNameType) {
      callbackFilter('none', showName ? 'showName' : undefined);
    } else {
      callbackFilter(e, showName ? 'showName' : undefined);
    }
  }, [filterType, showNameType]);
  // - Effect -
  useEffect(() => {
    document.addEventListener('click', clickDoc);
  }, [_ref]);
  useEffect(() => {
    return () => document.removeEventListener('click', clickDoc);
  }, []);
  return (
    <div
      className="sort_menu"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      ref={_ref}
    >
      <div
        className={`sort_menu__item ${sortType === 'name:up' ? 'selected' : ''}`}
        onClick={(e) => {
          onClickSortType(e, 'name:up');
        }}
      >ファイル名順↑
      </div>
      <div
        className={`sort_menu__item ${sortType === 'name:down' ? 'selected' : ''}`}
        onClick={(e) => {
          onClickSortType(e, 'name:down');
        }}
      >ファイル名順↓
      </div>
      <div
        className={`sort_menu__item ${sortType === 'date:up' ? 'selected' : ''}`}
        onClick={(e) => {
          onClickSortType(e, 'date:up');
        }}
      >撮影日順↑
      </div>
      <div
        className={`sort_menu__item ${sortType === 'date:down' ? 'selected' : ''}`}
        onClick={(e) => {
          onClickSortType(e, 'date:down');
        }}
      >撮影日順↓
      </div>
      <div className="sort_menu__divider" />
      <div
        className={`sort_menu__item ${filterType === 'unused' ? 'selected' : ''}`}
        onClick={(e) => {
          onClickFilterType('unused');
        }}
        tabIndex={-1}
      >使用済みを隠す
      </div>
      {/* <div
        // className="sort_menu__item"
        // onClick={(e) => { onClickFilterType(e) }}
        className={`sort_menu__item ${showNameType === 'unused' ? 'selected' : ''}`}
        onClick={(e) => {
          onClickFilterType('unused', 'showName');
        }}
        tabIndex={-1}
      >ファイル名を表示</div> */}
    </div>
  );
};
type ModalDisplayProps = {
  imgSrc: string,
  imgName: string,
}
const ModalDisplay = (props: ModalDisplayProps) => {
  const { imgSrc, imgName } = props;
  return (
    <div className="dialog_contents">
      <div className="dialog_contents__body"><img
        src={imgSrc}
        alt=""
      /></div>
      <div className="dialog_contents__footer">{imgName}</div>
    </div>
  );

};

export const LayoutImageList = (props: Props) => {
  const { unUsedEditableList, unUsedPhotoList, errorCount } = props;
  const dispatch = useDispatch();
  // - Params -
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const { orderInfo, xml, selectedImageList, orderSelect, stopWarning, isSaving } = useAppSelector((state) => ({
    orderInfo: state.orderPreparation.currentOrder,
    xml: state.xml[shopOrderId],
    selectedImageList: state.orderPreparation.selectedImageList,
    orderSelect: state.xml[shopOrderId]?.orderSelect,
    stopWarning: state.layout.isStopExifWarning,
    isSaving: state.layout.isSaving,
  }), lodash.isEqual);
  // - State -
  const [list, setList] = useState<EditableImage[]>([]);
  const [layoutMode, setLayoutMode] = useState<'one' | 'two'>('one');
  const [intersectionObserver, setIntersectionObserver] = useState<IntersectionObserver | null>(null);
  const [draggable, setDraggable] = useState(false);
  const [sortType, setSortType] = useState<SortType>('name:up');
  const [filterType, setFilterType] = useState<FilterType>('none');
  const [isSortMenu, setIsSortMenu] = useState(false);
  const [imageProcessorBusy, setImageProcessorBusy] = useState(false);
  const [totalProcess, setTotalProcess] = useState(0);
  const [finishProcess, setFinishProcess] = useState(0);
  const [showNameType, setShowNameType] = useState<FilterType>('none');
  const [ishHoverImage, setIsHoverImage] = useState(false);
  const [imageName, setImageName] = useState('');

  // - Memo -
  const loadUnUsedList = useMemo(() => {
    const viewList: null[] = [];
    const count =  unUsedPhotoList.length - unUsedEditableList.length - errorCount;
    for (let i = 0; i < count; i ++) {
      viewList.push(null);
    }
    return viewList;
  }, [unUsedEditableList, unUsedPhotoList, errorCount]);

  // - Callback -
  const onClickImageSelect = useCallback(() => {
    const input = document.createElement('input');
    const orderSelect = xml?.orderSelect?.metaModel.imageData ?? [];
    // const editableImageArr: EditableImage[] = [];
    let exifFlg = false;
    input.type = 'file';
    input.accept = '.jpg'
    input.multiple = true;
    input.onchange = (e) => {
      if (input.files) {
        const files = Array.from(input.files).filter((v) => /\.(jpe?g)$/i.test(v.name));
        UiManager.ins.emit(
          'r->l:add-image',
          {
            files: files,
            // files: Array.from(input.files),
            kijshopCd,
            shopOrderId,
            orderId: orderId || null,
            samePushIgnore: true,
            selectIdList: [...(orderSelect.map((v) => ({path: v.selectFileName?.real.path || '', selectID: v.selectID || ''})))],
            onCreatedEditableImage: (e) => {
            if (e.sameImage) return;
            // UiManager.ins.emit('l->r:post-editable-image-list', {
            //   callback: (v) => {
            //     // setList(v.list.filter(EditableImageManager.filter(kijshopCd, shopOrderId, orderId)));
                if (String(e.editableImage.exif.colorSpace) !== '1' && !exifFlg && !stopWarning) {
                  exifFlg = true;
                  dispatch(dialogActions.pushMessage({
                    title: '確認',
                    message: !e.editableImage.exif.colorSpace ? [
                      'Exifヘッダ情報に色空間の情報がありません。',
                      '色空間がsRGBでない場合、色が変わる可能性があります。',
                    ] : [
                      'sRGB以外の色空間が指定されています。',
                      '色空間がsRGBでない場合、色が変わる可能性があります。',
                    ],
                    buttons: [{
                      label: 'OK',
                      callback: () => {
                        dispatch(dialogActions.pop());
                      },
                    }],
                    remind: {
                      callback: (v) => dispatch(layoutActions.setIsStopExifWarning(v)),
                    },
                  }));
                }
              // },
            // })
            },
            onUploaded: (e) => {
              if (e.sameImage) return;
              // editableImageArr.push(e.editableImage);
              dispatch(xmlActions.uploadImage({ kijshopCd, shopOrderId }).layout(orderId || '').add(e.editableImage, (res) => {
                store.dispatch(layoutActions.setSaveSelect(res));
              }));
            },
          },
        );
      }
    };
    input.click();
  }, [stopWarning, xml]);
  const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.dropEffect = 'copy';
    e.preventDefault();
    setDraggable(true);
  }, []);
  const onDrop = useCallback(async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files).filter((v) => /\.(jpe?g)$/i.test(v.name));
    // Windows でのバグチェック
    if (!WindowsImgBugFix.check(files)) {
      setDraggable(false);
      return;
    }
    let exifFlg = false;
    setDraggable(false);
    const orderSelect = xml?.orderSelect?.metaModel.imageData ?? [];
    UiManager.ins.emit(
      'r->l:add-image',
      {
        files: files,
        // files: Array.from(e.dataTransfer.files),
        kijshopCd,
        shopOrderId,
        orderId: orderId || null,
        selectIdList: [...(orderSelect.map((v) => ({path: v.selectFileName?.real.path || '', selectID: v.selectID || ''})))],
        samePushIgnore: true,
        onCreatedEditableImage: (e) => {
          if (e.sameImage) return;
          // UiManager.ins.emit('l->r:post-editable-image-list', {
          //   callback: (v) => {
              // setList(v.list.filter(EditableImageManager.filter(kijshopCd, shopOrderId, orderId)).filter((v) => v.kind === '6'));
              if (String(e.editableImage.exif.colorSpace) !== '1' && !exifFlg && !stopWarning) {
                exifFlg = true;
                dispatch(dialogActions.pushMessage({
                  title: '確認',
                  message: !e.editableImage.exif.colorSpace ? [
                    'Exifヘッダ情報に色空間の情報がありません。',
                    '色空間がsRGBでない場合、色が変わる可能性があります。',
                  ] : [
                    'sRGB以外の色空間が指定されています。',
                    '色空間がsRGBでない場合、色が変わる可能性があります。',
                  ],
                  buttons: [{
                    label: 'OK',
                    callback: () => {
                      dispatch(dialogActions.pop());
                    },
                  }],
                  remind: {
                    callback: (v) => dispatch(layoutActions.setIsStopExifWarning(v)),
                  }
                }));
              }
          //   },
          // })
        },
        onUploaded: (e) => {
          if (e.sameImage) return;
          dispatch(xmlActions.uploadImage({ kijshopCd, shopOrderId }).layout(orderId || '').add(e.editableImage, (res) => {
            store.dispatch(layoutActions.setSaveSelect(res));
          }));
        },
      },
    );
  }, [stopWarning, xml]);
  const onDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggable(false);
  }, []);
  const onChangeEditableImageList = useCallback((list: EditableImage[]) => {
    setList(list);
  }, []);
  const onDoubleClickImage = useCallback((editableImage: EditableImage, imgName: string) => {
    // ダブルクリックした時の処理
    // UiManager.ins.emit('r->l:add-editable-image', { editableImage });
    // console.log(editableImage.editable);
    // r->l:add-editable-image を emit して発火
    dispatch(dialogActions.push({
      title: '',
      element: <ModalDisplay
        imgSrc={editableImage.thumbnailBase64}
        imgName={imgName}
      />,
      closeBtn: true,
      onBlurPop: true,
      className: 'img_modal_display',
    }));
  }, []);
  const onDragStartEditableImage = useCallback((editableImage: EditableImage) => {
    UiManager.ins.emit('r->l:editable-image:drag:start', { editableImage });
  }, []);
  const onDragEndEditableImage = useCallback((editableImage: EditableImage) => {
    UiManager.ins.emit('r->l:editable-image:drag:end', { editableImage });
  }, []);
  const editableImageListSortFunc = useCallback((a: EditableImage, b: EditableImage) => {
    switch (sortType) {
      case 'name:up':
        return a.name < b.name ? -1 : 1;
      case 'name:down':
        return a.name > b.name ? -1 : 1;
      case 'date:up':
        return a.exif.createDate < b.exif.createDate ? -1 : 1;
      case 'date:down':
        return a.exif.createDate > b.exif.createDate ? -1 : 1;
    }
  }, [sortType]);
  const editableImageListFilterFunc = useCallback((e: EditableImage) => {
    switch (filterType) {
      case 'none':
        return e.kind === '6';
      // return true;
      case 'unused':
        return !e.flags.used && e.kind === '6';
      default:
        return e.kind === '6';
    }
  }, [filterType]);
  // - Effect -
  // -- did mount & un mount --
  useEffect(() => {
    // --- did mount ---
    // --- 初期画像読み込み ---
    // if (orderSelect && orderSelect.metaModel.imageData) {
    //   orderSelect.metaModel.imageData.forEach((image) => {
    //     if (image.selectFileName && image.selectFileName.real.path) {
    //       RestoreEditableImageManager.ins.restore({
    //           kijshopCd,
    //           shopOrderId,
    //           orderId,
    //           path: `${kijshopCd}/upload/${shopOrderId}/${image.selectFileName.real.path}`,
    //           name: image.selectFileName.real.path,
    //           selectId: image.selectID || '',
    //         },
    //         true,
    //       );
    //     }
    //   });
    // }
    const tmpOnChangeEditableImageList = (e: { list: EditableImage[] }) => {
      // const resultList: EditableImage[] = [];
      onChangeEditableImageList(e.list.filter(EditableImageManager.filter(kijshopCd, shopOrderId, orderId)).filter((v) => v.kind === '6'));
    };
    UiManager.ins.on('l->r:change-editable-image-list', tmpOnChangeEditableImageList);
    const tmpOnGetImageProcessorStatus = (e: { status: 'busy' | 'standby'; total: number; finish: number }) => {
      setImageProcessorBusy(e.status === 'busy');
      setTotalProcess(e.total);
      setFinishProcess(e.finish);
    };
    UiManager.ins.on('l->r:get-image-processor-status', tmpOnGetImageProcessorStatus);
    UiManager.ins.emit('l->r:post-editable-image-list', {
      callback: (e: { list: EditableImage[] }) => {
        const resultList: EditableImage[] = [];
        for (const image of e.list ?? []) {
          if (image.shopOrderId === shopOrderId && image.kijshopCd === kijshopCd && image.kind === '6') {
            if (!resultList.find((v) => v.selectId === image.selectId)) {
              resultList.push(image);
            }
          }
        }
        setList(resultList);
      },
    });
    setIntersectionObserver(
      IntersectionObserverFactory.make('layout-image-list-container'),
    );
    return () => {
      // --- un mount ---
      UiManager.ins.off('l->r:change-editable-image-list', tmpOnChangeEditableImageList);
      UiManager.ins.off('l->r:get-image-processor-status', tmpOnGetImageProcessorStatus);
    };
  }, []);

  const onClickDelete = useCallback(async(img: EditableImage) => {
    if (!img.flags.uploaded) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['画像のアップロードが完了していません。'],
        buttons: [{
          label: 'はい', callback: () => dispatch(dialogActions.pop()),
        }]
      }))
      return;
    }
    const layoutInfo = ['20', '30'];
    const option = LayoutXmlUtile.getOptionInfoData(orderId, xml);
    const filterLayoutData = xml?.orderInfo?.infoData?.filter(
      (v) => (
        (v.xml.metaModel.id !== orderId && layoutInfo.includes(v.xml?.viewModel?.surrogateProcess?.id ?? ''))
        || (v.xml.metaModel.id !== orderId && !option.map((v2) => v2.xml.metaModel.id ?? '').filter((v2) => v2).includes(v.xml.metaModel.id ?? ''))
      )
    ) ?? [];
    const breakData = xml?.orderInfo?.infoData?.filter(
      (v) => v.xml.viewModel?.surrogateProcess?.id === '10'
    ) ?? [];
    const currentImageData = EditableImageManager.ins.list.filter((v) => v.flags.used && v.selectId).map((v) => v.selectId);
    const searchImage = (selectId: string) => {
      const result: string[] = [];
      /* 当オーダー以外の簡易レイアウト 完全レイアウトの画像チェック */
      for (const info of  filterLayoutData) {
        for (const parts of info.parts?.partsData ?? []) {
          for (const page of parts.page?.pageData ?? []) {
            for (const picData of page.viewModel.orderPicture?.data ?? []) {
              if (picData.selectID && picData.selectID !== 'null' && !result.includes(picData.selectID)) {
                result.push(picData.selectID);
              }
            }
          }
        }
      }
      /* おまかせ画像の画像チェック */
      for (const info of  breakData) {
        for (const picData of info.pageBreak?.viewModel.data?.image ?? []) {
          if (picData.selectID && picData.selectID !== 'null' && !result.includes(picData.selectID)) {
            result.push(picData.selectID);
          }
        }
      }
      for (const picData of currentImageData) {
        if (!result.includes(picData)) result.push(picData);
      }
      return !!result.find((v) => v === selectId);
    }
    if (searchImage(img.selectId)) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['画像が使用されているため削除できません。'],
        buttons: [{
          label: 'はい', callback: () => dispatch(dialogActions.pop()),
        }]
      }))
      return;
    }
    const prevOrderSelect = xml?.orderSelect

    if (prevOrderSelect) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [`${img.name}を削除します。`, 'よろしいですか？'],
        buttons: [
          { label: 'キャンセル', callback: () => dispatch(dialogActions.pop()) },
          {
            label: 'OK', callback: async () => {
              UiManager.ins.emit('l->r:wait-loading', { message: '画像の削除中です。' })
              /* 最新のorderSelect.xmlを取得(selectID被り対策) */
              const getOrderSelect = (xml: OrderSelectXml): Promise<OrderSelectXml> => new Promise((resolve, reject) => {
                dispatch(apiActions.run(new ApiGetXml(kijshopCd, shopOrderId, [xml.xmlUniqueName]),
                  {
                    onSuccess: async (res) => {
                      if (res.body.data) {
                        await xml.parse(res.body.data.fileinfo[0].body)
                        resolve(xml)
                      }
                    },
                    onError: () => reject(xml)
                  }))
              })
              const orderSelect = await getOrderSelect(prevOrderSelect);
              const target = orderSelect.metaModel.imageData?.find(v => v.selectID === img.selectId)
              dispatch(dialogActions.pop());
              // dispatch(xmlActions.uploadImage({ kijshopCd, shopOrderId }).delete(img, () => UiManager.ins.emit('l->r:wait-loading', {message: ''})));
              if (target) {
                target.selectCode = '0'
                orderSelect.build();
                const files: {
                  filename: string,
                  body: string,
                  is_create: '0' | '1'
                }[] = [{
                  filename: orderSelect.xmlUniqueName,
                  body: orderSelect.xml,
                  is_create: '0',
                }];
                dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }),
                  {
                    onSuccess: () => {
                      xml && dispatch(xmlActions.setXml({ shopOrderId, xml: xml }));
                      img.isDelete = true;
                      UiManager.ins.emit('l->r:wait-loading', { message: '' })
                    },
                    onError: (e) => {
                      UiManager.ins.emit('l->r:wait-loading', { message: '' })
                      dispatch(dialogActions.pushMessage({
                        title: '確認',
                        message: [
                          '削除処理が正常に実行されませんでした。',
                          '恐れ入りますがしばらく待ってから再度お試しください。'
                        ],
                        buttons: [{
                          label: 'はい', callback: () => dispatch(dialogActions.pop()),
                        }]
                      }))
                    },
                  },
                ));
              }
            }
          }
        ]
      }))
    } else {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['画像のアップロードが完了していません。'],
        buttons: [{
          label: 'はい', callback: () => dispatch(dialogActions.pop()),
        }]
      }))
    }
  }, [xml, orderId]);
  // --- 初期画像読み込み時の使用済みチェック ---
  // useEffect(() => {
  //   const infoData = xml?.orderInfo?.infoData?.find(data => data.xml.metaModel.id === orderId);
  //   infoData?.parts?.partsData?.forEach(v => {
  //     v.page?.pageData?.forEach(v2 => {
  //       v2.viewModel.orderPicture?.data.forEach(v3 => {
  //         const target = list.find((v) => v.selectId === v3.selectID);
  //         if (target) {
  //           target.flags.used = true;
  //         }
  //       });
  //     });
  //   });
  //   console.log(list)
  // }, [list]);

  // useEffect(() => {
  // }, [drawerList])

  return (
    <div
      className={`tool_contents tool_view_list${draggable ? ' draggable' : ''}`}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
    >
      <div className="tool_contents__inner">
        <div
          className="tool_view_list__header"
          onDragLeave={(e) => {
            e.stopPropagation();
          }}
        >
          {(imageProcessorBusy && !isSaving && !!totalProcess && totalProcess !== finishProcess) ? (
            <div>
              読み込み中...
              <div>
                {finishProcess} / {totalProcess}
              </div>
              <div>
                <progress
                  max={totalProcess}
                  value={finishProcess}
                />
              </div>
            </div>
          ) : (
            <>
              <div>画像一覧</div>
              <div>画像数：{list.filter(v => !v.isDelete).length}</div>
            </>
          )}
          <Button
            label="画像追加"
            icon={<i className="fas fa-plus" />}
            color="primary"
            onClick={() => {
              onClickImageSelect();
            }}
          >
          </Button>
          <div
            className="flex_box"
            style={{ gap: '0.25em' }}
          >
            <div
              className={`tool_view_list__header__btn ly${layoutMode === 'one' ? ' selected' : ''}`}
              onClick={() => setLayoutMode('one')}
            >
              <img
                src={iconOneCol}
                alt=""
              /><span>１列</span>
            </div>
            <div
              className={`tool_view_list__header__btn ly${layoutMode === 'two' ? ' selected' : ''}`}
              onClick={() => setLayoutMode('two')}
            >
              <img
                src={iconTwoCol}
                alt=""
              /><span>２列</span>
            </div>
          </div>
          <div
            className="tool_view_list__header__btn sort"
            onClick={(e) => {
              setIsSortMenu((prev) => !prev);
            }}
            tabIndex={-1}
          ><img
            src={iconSort}
            alt=""
          />ソート

            {isSortMenu ?
              <SortMenu
                onBlur={() => setIsSortMenu(false)}
                sortType={sortType}
                filterType={filterType}
                callbackSort={(e) => setSortType(e)}
                callbackFilter={(e, name) => {
                  if (name) {
                    setShowNameType(e);
                  } else {
                    setFilterType(e);
                  }
                }}
                showNameType={showNameType}
              />
              : (<></>)}
          </div>
        </div>
        <div className={`tool_view_list__body ${layoutMode}_col layout-image-list-container`}>
          <>
          {list
            .sort(editableImageListSortFunc)
            .sort((a, b) => a.isDelete === b.isDelete ? 0 : a.isDelete ? 1 : -1)
            .filter(editableImageListFilterFunc)
            .map((v, i) => (
              !v.isDelete &&
              <div
                key={`layout-image-list_${i}_${v.id}`}
                onDoubleClick={() => {
                  onDoubleClickImage(v, v.name);
                }}
                onDragStart={() => {
                  onDragStartEditableImage(v);
                }}
                onDragEnd={() => {
                  onDragEndEditableImage(v);
                }}
                // onMouseOver={(e) => {
                //   setIsHoverImage(true)
                //   setImageName(v.name);
                // }}
                // onMouseLeave={() => {
                //   setIsHoverImage(false)
                //   setImageName('');
                // }}
                title={`${v.name}\n${'2021/11/23 14:20'}`}
                className="tool_view_list__body__thumb"
              >
                <div className="tool_view_list__body__thumb__img">
                  <LazyLoadImage
                    observer={intersectionObserver}
                    src={v.thumbnailBase64}
                    className="tool_view_list__item"
                  />
                  {v.flags.used && <div className="tool_view_list__body__thumb__check" />}
                  <div className="tool_view_list__body__thumb__check delete" onClick={() => onClickDelete(v)} />
                  <ImageUploadStatus
                    uploaded={v.flags.uploaded}
                    uploading={v.flags.uploading}
                  />
                  {/* {ishHoverImage && showNameType === 'none' && imageName === v.name ? (
                  <StaticTooltip
                    messages={[imageName]}
                    pos={{x: '0', y: '50%'}}
                  />
                ) : (<></>)} */}
                </div>
                {/* {showNameType === 'unused' ? ( */}
                <div className="tool_view_list__body__thumb__info">
                  <div>{v.name.length > 40 ? `${v.name.slice(0, 40)}...` : v.name}</div>
                  <div>{v.exif.createDate.toLocaleString()}</div>
                </div>
                {/* ) : (<></>)} */}
              </div>
            ))}
            {loadUnUsedList.map((_, i) => (
              <div
                className="tool_view_list__body__thumb dummy_thumb"
                key={`dummy_${i}`}
              >
                <div
                className="loading_popup__dots"
                style={{ fontSize: 6, animationDuration: `${1}s`, WebkitAnimationDuration: `${1}s` }}
                />
             </div>
             ))}
          </>
        </div>
        {/* DnDガイダンス */}
        {list.length ? (<></>)
          : <div
            className={`tool_view_list__guidance ${layoutMode}_col`}
            onDragLeave={(e) => {
              e.stopPropagation();
            }}
          >
            <img
              src={iconDnd}
              alt=""
            />
            <span>ファイルをドラッグ＆ドロップして画像を貼り付けてください</span>
          </div>
        }
      </div>
    </div>
  );
};
