import React, { useState } from 'react';
import { Button } from '../../components/ui/button/button';
import { ConfigHeaderItems } from '../ui/header/config-header-items';
import { Header } from '../ui/header/header';
import { Calendar } from '../ui/input/calendar';
import { Checkbox } from '../ui/input/checkbox';
import { ColorPicker } from '../ui/input/color-picker';
import { FileSelect } from '../ui/input/file-select';
import { Input } from '../ui/input/input';
import { Radiobutton } from '../ui/input/radiobutton';
import { LoadingBar } from '../ui/loading/loading-bar';
// import { LoadingPopup } from '../ui/loading/loading-popup';
import { Select } from '../ui/select/select';
import { Sidebar } from '../ui/sidebar/sidebar';
import { Table } from '../ui/table/table';
import { TextArea } from '../ui/textarea/textarea';

// - UIコンポネントリスト -
type UiList = {
  title: string,
  component: JSX.Element,
}

// - プルダウンリスト -
type SelectList = {
  value: string,
  label: string,
}

export const DebugUi = () => {
  // - State -
  // -- UIリスト --
  const [uiList, setUiList] = useState<UiList[]>([]);
  // - Callback -
  // -- UIコンポネント開く --
  const handlerOpenUi = (component: JSX.Element, title: string) => {
    setUiList([...uiList, {title, component}]);
  }
  // -- ボタンlabel --
  const [buttonLabel, setButtonLabel] = useState('');
  // -- インプットvalue --
  const [radioState, setRadioState] = useState ('float');
  // -- ラジオボタンlabel --
  const [radioLabel, setRadioLabel] = useState('');
  // -- プルダウンvalue --
  const [inputSelectValue, setInputSelectValue] = useState('');
  // -- プルダウンlabel --
  const [inputSelectLabel, setInputSelectLabel] = useState('');
  // -- プルダウンlist --
  const [inputSelectList, setInputSelectList] = useState<SelectList[]>([])
  // -- プルダウン選択value --
  const [selectValue, setSelectValue] = useState('');
  // -- テーブルhead --
  const [inputHead, setInputHead] = useState('');
  // -- テーブルheadリスト --
  const [inputHeadList, setInputHeadList] = useState<string[]>(['aaa', 'bbb', 'ccc']);
  // -- テーブルbody --
  const [inputBody, setInputBody] = useState('');
  // -- テーブルbodyリスト --
  const [inputBodyList, setInputBodyList] = useState<string[][]>([['aaa', 'aaa', 'aaa'], ['bbb', 'bbb', 'bbb'], ['ccc', 'ccc', 'ccc']]);
  // -- テーブルselected --
  const [inputRowNum, setInputRowNum] = useState<number>();
  // -- テーブルminimumNumOfRows --
  const [minNumOfRow, setMinNumOfRow] = useState<number>();
  // -- テーブルinsertPage-index --
  const [insertNum, setInsertNum] = useState<number>();
  return (
    <div style={{ height: '100%', overflow: 'auto' }}>

      {/* props
        label：ボタンに表示する文字
        defaultProps
      */}
      {/* 入力値からボタンのラベル決定 */}
      <br />
      <div>
        label:<input
          value={buttonLabel}
          onChange={(e) => setButtonLabel(e.target.value)}
        />
      </div>
      <div>
        <Button
          label="ボタン"
          onClick={() => handlerOpenUi(
            <Button label={buttonLabel} />,
            'ボタン'
          )}
        />
      </div>


      {/* props なし */}
      <br />
      <div>
        <Button
          label="ヘッダー"
          onClick={() => handlerOpenUi(<Header />, 'ヘッダー')}
        />
      </div>


      {/* ヘッダーの設定ボタン配下 */}
      {/* props なし */}
      <br />
      <div>
        <Button
          label="ヘッダーアイテム"
          onClick={() => handlerOpenUi(<ConfigHeaderItems />, 'ヘッダーアイテム')}
        />
      </div>


      {/* props
        getFormatDate：フォーマットされた日付を取得
        defaultProps
      */}
      {/* 取得した値をログ出力 */}
      <br />
      <div>
        <Button
          label="カレンダー"
          onClick={() => handlerOpenUi(
            <Calendar
              getFormatDate={(date) => console.log(date)}
              callbackClickHelp={() => {}}
            />,
            'カレンダー'
          )}
        />
      </div>


      {/* props
        defaultProps
      */}
      {/* 特に設定無しで表示 */}
      <br />
      <div>
        <Button
          label="チェックボックス"
          onClick={() => handlerOpenUi(
            <Checkbox />,
            'チェックボックス'
          )}
        />
      </div>


      {/* props
        getFiles：参照したファイルの情報を取得
        defaultProps
      */}
      {/* 取得した値をログ出力 */}
      <br />
      <div>
        <Button
          label="ファイル選択"
          onClick={() => handlerOpenUi(
            <FileSelect
              getFiles={(file) => console.log(file)}
            />,
            'ファイル選択'
          )}
        />
      </div>


      {/* props
        handlerEnter：エンターキー押下時の処理
        isInputInteger：整数or少数の半角英数字の入力に制限できる
        defaultProps
      */}
      {/* 少数または整数の選択値から表示 */}
      <br />
      <div>
        <Select
          value={radioState}
          list={[{value: "float", label: "少数"}, {value: "integer", label: "整数"}]}
          onChange={(e) => setRadioState(e.target.value)}
        />
      </div>
      <div>
        <Button
          label="インプット"
          onClick={() => handlerOpenUi(
            <Input
              isInputInteger={radioState === 'integer' ? true : false}
            />,
            'インプット'
          )}
        />
      </div>


      {/* props
        label：文字設定
        checkState：チェックする要素
        defaultProps
      */}
      {/* 特に設定なしで表示 */}
      <br />
      <div>
        label:<input
          value={radioLabel}
          onChange={(e) => setRadioLabel(e.target.value)}
        />
      </div>
      <div>
        <Button
          label="ラジオボタン"
          onClick={() => handlerOpenUi(
            <Radiobutton
              label={radioLabel}
            />,
            'ラジオボタン'
          )}
        />
      </div>


      {/* props なし */}
      <br />
      <div>
        <Button
          label="ローディング"
          onClick={() => handlerOpenUi(
            <LoadingBar message="注文データをコピーしています" progressNum={83} />,
            'ローディング'
          )}
        />
      </div>


      {/* props
        value：選択したい値(listのvalue)を設定
        list：選択肢(value と labelのオブジェクト)を配列で設定
        defaultProps
      */}
      {/* プルダウンを入力値から表示 */}
      <br />
      <div>
        value:<input
          value={inputSelectValue}
          onChange={(e) => setInputSelectValue(e.target.value)}
        />
        label:<input
          value={inputSelectLabel}
          onChange={(e) => setInputSelectLabel(e.target.value)}
        />
        <Button
          label="push"
          onClick={() => {
            setInputSelectList([...inputSelectList, {value: inputSelectValue, label: inputSelectLabel}]);
            setInputSelectValue('');
            setInputSelectLabel('');
          }}
        />
      </div>
      <div>
        <Button
          label="プルダウン"
          onClick={() => handlerOpenUi(
            <Select
              value={selectValue}
              list={inputSelectList}
              onChange={(e) => setSelectValue(e.target.value)}
            />,
            'プルダウン'
          )}
        />
        {selectValue}
      </div>


      {/* props なし */}
      <br />
      <div>
        <Button
          label="サイドバー"
          onClick={() => handlerOpenUi(
            <Sidebar />,
            'サイドバー'
          )}
        />
      </div>


      {/* props
        head：テーブルのヘッダー部分(配列で設定)
        body：テーブルの行：行ごとに配列にして設定(多次元配列)
        selected：塗りつぶしの行を設定
        handlerSelect：bodyクリック時にはしる関数
        minimumNumOfRows：行最低数
        insertPage：行差し込み
        defaultProps
      */}
      {/* insertのelementの値は固定 */}
      <br />
      <div>
        head:<input
          value={inputHead}
          onChange={(e) => setInputHead(e.target.value)}
        />
        (e.g. aaa,bbb,ccc)
        <Button
          label="push"
          onClick={() => {
            setInputHeadList(inputHead.split(','));
            setInputHead('');
          }}
        /><br />
        body:<input
          value={inputBody}
          onChange={(e) => setInputBody(e.target.value)}
        />
        (e.g. aaa,aaaa,aaaaa)
        <Button
          label="push"
          onClick={() => {
            const bodyList = inputBody.split(',');
            setInputBodyList([...inputBodyList, bodyList]);
            setInputBody('');
          }}
        /><br />
        selected:<input
          value={inputRowNum}
          onChange={(e) => setInputRowNum(Number(e.target.value))}
        /><br />
        minimumNumOfRows:<input
          value={minNumOfRow}
          onChange={(e) => setMinNumOfRow(Number(e.target.value))}
        /><br />
        insertPage: index:<input
          value={insertNum}
          onChange={(e) => setInsertNum(Number(e.target.value))}
        />
      </div>
      <div>
        <Button
          label="テーブル"
          onClick={() => handlerOpenUi(
            <Table
              head={inputHeadList}
              body={inputBodyList}
              selected={inputRowNum}
              // insertPage={insertNum ? {index: insertNum, element: <p>あああ</p>} : undefined}
            />,
            'テーブル'
          )}
        />
      </div>


      {/* props
        handlerEnter：エンターキー押下時の処理
        defaultProps
      */}
      {/* 特に指定なしで表示 */}
      <br />
      <div>
        <Button
          label="テキストエリア"
          onClick={() => handlerOpenUi(
            <TextArea />,
            'テキストエリア'
          )}
        />
      </div>


      {/* props
        onGetColor：選択したカラーコード取得
        colorList: 色一覧
        defaultProps
      */}
      {/* 取得したカラーコードをログ出力 */}
      <br />
      <div>
        <Button
          label="カラーピッカー"
          onClick={() => handlerOpenUi(
            <ColorPicker
              selectColor={'#000000'}
              onGetColor={(color) => console.log(color)}
            />,
            'カラーピッカー'
          )}
        />
      </div>

      {/* ローディング */}
      {/*<br />*/}
      {/*<div>*/}
      {/*  <Button*/}
      {/*    label="ローディング"*/}
      {/*    onClick={() => handlerOpenUi(*/}
      {/*      <LoadingPopup />,*/}
      {/*      'ローディング'*/}
      {/*    )}*/}
      {/*  />*/}
      {/*</div>*/}


      {/* UI表示部分 */}
      <br />
      <div>
        <div>
          {uiList[uiList.length - 1]?.title}
        </div>
        <div>
          {uiList[uiList.length - 1]?.component}
        </div>
      </div>

    </div>
  )
}
