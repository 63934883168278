import { Header } from '../../ui/header/header';
import { NewsList } from './news-list';
import { OrderTable } from './order-table';
import { LogDecorator } from '@tenryu/log-decorator';
import { useEffect } from 'react';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { orderPreparationActions } from '../../../slices/order-preparation-slice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { commonActions } from '../../../slices/common-slice';
import { EditableImageManager } from '../../../layout-editor/manager/editable-image/editable-image.manager';

export const OrderList = () => {
  const dispatch = useDispatch();
  const { kijshopCd } = useParams<PathParams>();
  const { isClickNews, master } = useAppSelector((state) => ({
    isClickNews: state.newsList.isClickNews,
    master: state.common.data.masterShop,
  }), lodash.isEqual);
  // - Effect -
  // -- ログまとめ --
  useEffect(() => {
    LogDecorator.group('<green>注文一覧</green>');
    return () => LogDecorator.groupEnd();
  }, []);
  useEffect(() => {
    EditableImageManager.ins.clear('list');
    dispatch(orderPreparationActions.setOrderList([], kijshopCd));
  }, []);
  useEffect(() => {
    if (!master) {
      dispatch(commonActions.getData(kijshopCd));
    }
    dispatch(commonActions.checkBrowser());
    dispatch(commonActions.checkStaffManagementAuth(kijshopCd));
    dispatch(commonActions.getStaffLoginInfo(kijshopCd));
  }, []);
  return (
    <div className="page_base">
      <Header page={isClickNews ? 'news' : 'order'} />
      <div className="list_page_contents">
        {isClickNews ? <NewsList /> : <OrderTable />}
      </div>
    </div>
  );
};
