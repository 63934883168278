import { ApiBaseFront } from '../api-base-front';

export type TokenPostRequest = {
  kijshopCd: string,
  password: string,
};

export type TokenPostResponse = {
  kijshopCd: string,
  shopName: string,
  sessionId: string,
  token: string,
}

export type TokenPostForStaffRequest = {
  kijshopCd: string,
  staffId: string,
  password: string,
}

export type TokenPostForStaffResponse = {
  kijshopCd: string,
  shopName: string,
  sessionId: string,
  token: string,
  staffId: string,
  userName: string,
  roleType: boolean, // 0:店舗, 1:スタッフ
  status: boolean, // 0:仮会員, 1:本会員
  id: string
}

export class ApiTokenPost extends ApiBaseFront<TokenPostResponse> {
  constructor(param: TokenPostRequest) {
    super(
      'POST',
      '/api/v1/token',
      'JSON',
      param,
    );
  }
}

export class ApiTokenDelete extends ApiBaseFront {
  constructor(param: { kijshopCd: string }) {
    super(
      'DELETE',
      '/api/v1/token',
      'JSON',
      param,
    );
  }
}

// スタッフログイン
export class ApiTokenPostForStaff extends ApiBaseFront<TokenPostForStaffResponse> {
  constructor(param: TokenPostForStaffRequest) {
    super(
      'POST',
      '/api/v1/token/staff',
      'JSON',
      param,
    );
  }
}