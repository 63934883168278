import { ApiBaseFront } from '../api-base-front';
import {
  AddStaffRequest,
  ChangeMailAddressSendMailRequest,
  ChangePasswordRequest, ChangePrePasswordRequest,
  RegisterMailRequest,
  RegisterPasswordRequest,
  RegisterStaffPasswordRequest,
  RequestKijshopcdOnly,
  RequestMailTokenOnly,
  ShopInfoResponse,
  StaffListResponse,
  UpdateStaffRequest,
  UpdateStaffStatusRequest,
} from '../../../models/api/front/staff-management';

// - スタッフ管理機能が有効かどうか -
export class ApiCheckStaffMngEnabled extends ApiBaseFront {
  constructor(param: RequestKijshopcdOnly) {
    super(
      'POST',
      '/api/v1/shop/staff_oncheck',
      'JSON',
      param,
    );
  };
}

export class ApiCheckStaffLogin extends ApiBaseFront {
  constructor(param: RequestKijshopcdOnly) {
    super(
      'GET',
      '/api/v1/staff/me',
      'JSON',
      param,
    );
  };
}

// - 店舗情報取得(システム設定→店舗設定) -
export class ApiGetShopInfo extends ApiBaseFront<ShopInfoResponse> {
  constructor(kijShopCd: string) {
    super(
      'GET',
      `/api/v1/shop/${kijShopCd}`,
      'JSON',
      {},
    );
  };
}

// - スタッフ管理機能有効化ウィザード(システム設定→店舗設定→スタッフ管理機能→ON) -
// -- [Step2] メールアドレス登録、メール送信 --
export class ApiPostStaffMngRegisterMail extends ApiBaseFront {
  constructor(param: RegisterMailRequest) {
    super(
      'POST',
      '/api/v1/shop/mail',
      'JSON',
      param
    );
  };
}

// -- [Step4] パスワード登録 --
export class ApiPostStaffMngRegisterPassword extends ApiBaseFront {
  constructor(param: RegisterPasswordRequest) {
    super(
      'POST',
      '/api/v1/shop/mail/store_password',
      'JSON',
      param,
    );
  };
}

// - 店舗メールアドレス変更ウィザード(システム設定→店舗設定→店舗メールアドレス→変更) -
// -- [Step2] メールアドレス変更(メール送信) --
export class ApiPostShopMailAddressChangeSendMail extends ApiBaseFront {
  constructor(param: ChangeMailAddressSendMailRequest) {
    super(
      'POST',
      '/api/v1/shop/mail/reset_mailaddress',
      'JSON',
      param,
    );
  };
}
// - 店舗メールアドレス変更ウィザード(システム設定→店舗設定→店舗メールアドレス→変更) -
// -- [Step4] メールアドレス変更 --
export class ApiPostShopMailAddressChange extends ApiBaseFront {
  constructor(param: RequestMailTokenOnly) {
    super(
      'POST',
      '/api/v1/shop/mail/change_mailaddress',
      'JSON',
      param,
    );
  }
}

// - 店舗パスワード変更ウィザード(システム設定→店舗設定→店舗パスワード→変更) -
// -- [Step2] パスワード変更 --
export class ApiPostShopPasswordChange extends ApiBaseFront {
  constructor(param: ChangePasswordRequest) {
    super(
      'POST',
      '/api/v1/shop/chgpassword',
      'JSON',
      param,
    );
  };
}

// - スタッフ一覧取得(スタッフ管理) -
export class ApiGetStaffList extends ApiBaseFront<StaffListResponse> {
  constructor(param: RequestKijshopcdOnly) {
    super(
      'GET',
      '/api/v1/staff',
      'JSON',
      param,
    );
  };
}

// - スタッフ追加(スタッフ管理→新規作成) -
export class ApiPostStaffAdd extends ApiBaseFront {
  constructor(param: AddStaffRequest) {
    super(
      'POST',
      '/api/v1/staff',
      'JSON',
      {
        kijshopCd: param.kijshopCd,
        staffId: param.staffId,
        name: param.name,
        mailaddress: param.mailaddress,
        password: param.password,
        staffIdType: param.staffIdType ? 1 : 0,
      },
    );
  };
}

// - スタッフ編集(スタッフ管理→編集) -
export class ApiPostStaffUpdate extends ApiBaseFront {
  constructor(id: string, param: UpdateStaffRequest) {
    super(
      'POST',
      `/api/v1/staff/${id}`,
      'JSON',
      {
        kijshopCd: param.kijshopCd,
        mailaddress: param.mailaddress,
        name: param.name,
      },
    );
  };
}

// - スタッフ仮パスワード変更(スタッフ管理→仮登録スタッフ編集) -
export class ApiPostStaffPrePasswordChange extends ApiBaseFront {
  constructor(id: string, param: ChangePrePasswordRequest) {
    super(
      'POST',
      `/api/v1/staff/${id}/pre_password`,
      'JSON',
      {
        kijshopCd: param.kijshopCd,
        password: param.password,
        password_confirmation: param.password_confirmation,
      }
    );
  };
}

// - スタッフロック解除(スタッフ管理→編集→ロック解除) -
export class ApiPostStaffUnlock extends ApiBaseFront {
  constructor(id: string, param: RequestKijshopcdOnly) {
    super(
      'POST',
      `/api/v1/staff/${id}/lockoff`,
      'JSON',
      param,
    );
  };
}

// - スタッフ無効(スタッフ管理→編集→無効) -
export class ApiPostStaffDisable extends ApiBaseFront {
  constructor(id: string, param: UpdateStaffStatusRequest) {
    super(
      'POST',
      `/api/v1/staff/${id}/change_status`,
      'JSON',
      {
        kijshopCd: param.kijshopCd,
        validFlg: param.validFlg ? 1 : 0,
      },
    );
  };
}

// - スタッフパスワード登録 -
export class ApiPostStaffPasswordRegister extends ApiBaseFront {
  constructor(id: string, param: RegisterStaffPasswordRequest) {
    super(
      'POST',
      `/api/v1/staff/${id}`,
      'JSON',
      {
        kijshopCd: param.kijshopCd,
        password: param.password,
        password_confirmation: param.password_confirmation,
      },
    );
  }
}