
export const webPagePath = {
  // help: 'https://support.labonetwork.co.jp/products/dioss-ppm/?utm_source=ppm&utm_medium=software&utm_campaign=helpbtn01',
  help: 'https://support.labonetwork.co.jp/products/ppm-cloud/',
  conflictShopOrderId: 'https://support.labonetwork.co.jp/knowledge-base/ppmcloud-001/',
  // NOTE: 緊急対応時、メッセージ 2023.07.07 kurokawa
  // conflictShopOrderId: 'https://cs.labonetwork.co.jp/ppmcloud/faq/ppmcloud0707/',
  // スタッフ管理機能とは
  whatIsStaffManagement: 'https://cs.labonetwork.co.jp/ppmcloud/faq/howtouse/ppmcloud-301/',
  // 発注状況をお知らせするメールアドレスとの違い
  differenceBetweenOrderStatusAndMailAddress: 'https://cs.labonetwork.co.jp/ppmcloud/faq/howtouse/ppmcloud-302/',
  // パスワードが分かりません。どうすればよろしいですか？
  forgotPassword: 'https://cs.labonetwork.co.jp/ppmcloud/faq/login/ppmcloud-060/',
};
