import Worker from '../../../worker';
import exifr from 'exifr';
import ExifReader from 'exifreader';
import Compressor from 'compressorjs';
import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { UiManager } from '../ui/ui.manager';
import { EditableImageManager } from '../editable-image/editable-image.manager';
import EventEmitter from 'eventemitter3';
import { Process } from './process';
import { UploadRequest } from '../upload/upload-request';
import { UploadManager } from '../upload/upload.manager';
import { ApiImagesGet, ImagesGetResponse } from '../../../api/front/images/api-images';
import { ResponseBase } from '../../../api/response-base';
import { getOrientation, Orientation } from 'get-orientation/browser';
import lodash, { cloneDeep } from 'lodash';

type EventType = {
  'change-queue': (e: {}) => void,
};

export class ImageProcessorManager extends EventEmitter<EventType> implements ILayoutEditorManagerBase {

  private static _ins: ImageProcessorManager;
  private initialized = false;
  private processCounter = 0;
  private processFinishCounter = 0;
  private processQueue: Process[] = [];
  private uiManager!: UiManager;
  private editableImageManager!: EditableImageManager;
  private uploadManager!: UploadManager;
  private isBusy = false;

  private constructor() {
    super();
  }

  static get ins() {
    if (ImageProcessorManager._ins) {
      return ImageProcessorManager._ins;
    }
    ImageProcessorManager._ins = new ImageProcessorManager();
    return ImageProcessorManager._ins;
  }

  destroy(): void {
    this.processCounter = 0;
    this.processFinishCounter = 0;
    this.processQueue = [];
    this.isBusy = false;
  }

  di(): void {
  }

  initialize() {
    if (this.initialized) {
      // console.error('すでに初期化済みです !!');
      return;
    }
    this.editableImageManager = EditableImageManager.ins;
    this.uiManager = UiManager.ins;
    this.uploadManager = UploadManager.ins;
    this.initialized = true;
    this.processCounter = 0;
    this.processFinishCounter = 0;
    this.processQueue = [];
    this.isBusy = false;
    this.addEvent();
  }

  enqueue(...process: Process[]) {
    this.processQueue.push(...process);
    // NOTE: kindが6の場合のみ、ユーザーが手動で消した可能性があるため復元をViewには反映しないようにする
    const isPicture = process.find(v => v.kind === '6');
    if (isPicture) {
      this.processCounter += process.filter(v => !v.isDelete).length;
      this.processQueue.sort((a, b) => a.isDelete === b.isDelete ? 0 : a.isDelete ? 1 : -1);
    }
    this.emit('change-queue', {});
  }

  dequeue() {
    this.processQueue.splice(0, 1);
    this.emit('change-queue', {});
  }

  async do(process: Process) {
    let editableImage = await process.all();
    editableImage.isDelete = !!process.isDelete
    const sameImage = process.hashData?.checkList && process.hashData.checkList.find((v) => v.hash === editableImage.hash);
    if (sameImage?.orientation) {
      editableImage.exif.orientation = sameImage.orientation;
    }
    let sameSelectId = '';
    // const resultEditableImage = pushIgnore ? sameImage : editableImage;
    if (sameImage && process.hashData?.selectIdList) {
      /* ラボ発注済みの画像は使用画像からアップロード画像を引っ張ってくる関係でパスにディレクトリが含まれる形になるため厳密等価では見ない */
      const selectID = process.hashData.selectIdList.find((v) => v.path.indexOf(sameImage.path) !== -1 && !editableImage.isDelete)?.selectID;
      if (selectID) {
        sameSelectId = selectID;
        editableImage.selectId = selectID;
      }
    }
    if (!process.samePushIgnore || !sameSelectId) {
      if (process.kind === '4' && process.samePushIgnore && sameImage) {
        const sameEditable = this.editableImageManager.list.find((v) => v.hash === editableImage.hash);
        editableImage = sameEditable ?? editableImage;
      } else {
        this.editableImageManager.push('list', editableImage);
      }
    } else {
      const sameEditable = this.editableImageManager.list.find((v) => v.selectId === sameSelectId);
      editableImage = sameEditable ?? editableImage;
      editableImage.isDelete = false;
    }
    const isSame = Boolean(sameImage && (process.kind !== '6' || sameSelectId));
    process.onCreatedEditableImage(editableImage, isSame);
    if (!editableImage.flags.uploaded) {
      // - アップロード -
      const editableImageId = editableImage.id;
      this.editableImageManager.toUploading(editableImageId);
      if (!isSame && editableImage.original) {
        const uploadRequest = new UploadRequest(
          editableImage,
          editableImage.original,
          () => {
            this.editableImageManager.toUploading(editableImageId);
          },
          (path) => {
            this.editableImageManager.toUploading(editableImageId, false);
            this.editableImageManager.toUploaded(editableImageId, path);
            editableImage.path = path;
            process.onUploaded(editableImage);
          },
          () => {
            this.editableImageManager.toUploading(editableImageId, false);
            this.editableImageManager.toUploaded(editableImageId, '', false);
            process.onError?.();
          },
        );
        this.uploadManager.enqueue(uploadRequest);
        // process.onCreatedEditableImage(editableImage);
      } else {
        this.editableImageManager.toUploading(editableImageId, false);
        this.editableImageManager.toUploaded(editableImageId, editableImage.path);
        editableImage.path = sameImage?.path || '';
        process.onUploaded(editableImage, Boolean(isSame));
      }
    }
  }

  private addEvent() {
    // 画像アップロードは各種でイベント作成
    this.uiManager.on('r->l:add-image', async (e) => {
      // console.log('r->l:add-image : ', e);
      // process を作る
      const existImageList: ImagesGetResponse[] = await new ApiImagesGet({ kijshopCd: e.kijshopCd, shopOrderId: e.shopOrderId, kind: '6' })
        .do()
        .then((res) => (res as ResponseBase<ImagesGetResponse[]>)?.body?.data || [])
        .catch(() => []);
      const hashList: { path: string, hash: string, orientation?: Orientation }[] = existImageList
        .map((v) => ({ path: v.filename, hash: v.hash || '', orientation: v.exifOrientation }))
        .filter((v) => v.hash);
      const existImage = existImageList.find(v => `${v.pathThumb}/${v.filename}` === e.path);
      const processList = e.files.map((file, i) => new Process(
        !e.restore ? file : null,
        e.kijshopCd,
        e.shopOrderId,
        e.orderId,
        '6',
        e.onUploaded ? ((p, sameImage) => e.onUploaded?.({ editableImage: p, index: i, sameImage })) : undefined,
        e.onCreatedEditableImage ? ((p, sameImage) => e.onCreatedEditableImage?.({ editableImage: p, index: i, sameImage })) : undefined,
        e.restore && existImage ?
          {
            originalName: existImage.orgFilename,
            name: existImage.filename,
            exif: {
              colorSpace: existImage.exifColorSpace,
              model: existImage.exifModel,
              orientation: existImage.exifOrientation,
              make: existImage.exifMake,
              createDate: existImage.exifCreated,
            },
            width: existImage.width,
            height: existImage.height,
            thumbnailUrl: existImage.pathThumb,
            hash: existImage.hash || '',
          } : null,
        e.restore,
        e.path,
        e.selectId,
        e.isUseCheck,
        { create: true, checkList: hashList, selectIdList: e.selectIdList },
        e.samePushIgnore,
      ));
      // enqueue する
      this.enqueue(...processList);
    });
    this.uiManager.on('r->l:add-image:restore', async (e) => {
      const existImageList: ImagesGetResponse[] = await new ApiImagesGet({ kijshopCd: e.kijshopCd, shopOrderId: e.shopOrderId, kind: '6' })
        .do()
        .then((res) => (res as ResponseBase<ImagesGetResponse[]>)?.body?.data || [])
        .catch(() => []);
      const hashList: { path: string, hash: string, orientation?: Orientation }[] = existImageList
        .map((v) => ({ path: v.filename, hash: v.hash || '', orientation: v.exifOrientation }))
        .filter((v) => v.hash);
      const unloadUsedDrawerImageList = [...e.list];
      const finishedProcessList: Process[] = [];
      const processList = existImageList.map((existImage) => {
        const usedDrawerImage = e.list.find((v) => (existImage.selectId && existImage.selectId === v.selectID) || v.selectFileName.real.path === existImage.filename);
        if (!usedDrawerImage) {
          return null;
        }
        const process = new Process(
          null,
          e.kijshopCd,
          e.shopOrderId,
          e.orderId,
          '6',
          undefined,
          (ei) => {
            finishedProcessList.push(process);
            e.onRestoreOne();
            if (finishedProcessList.length === unloadUsedDrawerImageList.length) {
              console.log('restore all !!');
            }
          },
          {
            originalName: existImage.orgFilename,
            name: existImage.filename,
            exif: {
              colorSpace: existImage.exifColorSpace,
              model: existImage.exifModel,
              orientation: existImage.exifOrientation,
              make: existImage.exifMake,
              createDate: existImage.exifCreated,
            },
            width: existImage.width,
            height: existImage.height,
            thumbnailUrl: existImage.pathThumb,
            hash: existImage.hash || '',
          },
          true,
          existImage.path,
          usedDrawerImage.selectID,
          undefined,
          undefined,
          true,
          e.isDelete ? usedDrawerImage.selectCode !== '1' : false,
        );
        return process;
      })
        .filter((v) => v) as Process[];
      console.group('===== debug =====');
      console.log('processList : ', processList);
      console.groupEnd();
      this.enqueue(...processList);
    });
    // PNG画像アップロード
    this.uiManager.on('r->l:add-image-png', (e) => {
      const processList = e.files.map((file, i) => new Process(
        file,
        e.kijshopCd,
        e.shopOrderId,
        e.orderId,
        '5',
        e.onUploaded ? ((p) => e.onUploaded?.({ editableImage: p, index: i})) : undefined,
        e.onCreatedEditableImage ? ((p) => e.onCreatedEditableImage?.({ editableImage: p, index: i })) : undefined,
        null,
        e.restore,
        e.path,
      ));
      this.enqueue(...processList);
    });
    // 完成画像アップロード
    this.uiManager.on('r->l:add-image-comp', (e) => {
      // process を作る
      const processList = e.files.map((file, i) => new Process(
        file,
        e.kijshopCd,
        e.shopOrderId,
        e.orderId,
        '1',
        e.callback ? ((p) => e.callback?.({ editableImage: p })) : undefined,
        undefined,
        null,
        e.restore,
        e.path,
      ));
      // enqueue する
      this.enqueue(...processList);
    });
    // レイアウトサムネイルアップロード
    this.uiManager.on('r->l:add-layout-comp', (e) => {
      // process を作る
      const process = new Process(
        e.file,
        e.kijshopCd,
        e.shopOrderId,
        e.orderId,
        '2',
        e.callback ? ((p) => e.callback?.({ editableImage: p })) : undefined,
        undefined,
        null,
        e.restore,
        e.path,
        '',
        false,
        undefined,
        undefined,
        undefined,
        e.onError,
      );
      // enqueue する
      this.enqueue(process);
    });
    // ロゴ画像アップロード
    this.uiManager.on('r->l:add-image-logo', (e) => {
      const process = new Process(
        e.file,
        e.kijshopCd,
        e.shopOrderId,
        e.orderId,
        '4',
        (p) => e.callback({ editableImage: p }),
        (p) => e.onCreatedEditableImage?.({ editableImage: p }),
        null,
        e.restore,
        e.path,
        undefined,
        undefined,
        e.hashData,
        e.samePushIgnore,
      );
      // enqueue する
      this.enqueue(process);
    });
    // PNG画像アップロード
    this.uiManager.on('r->l:add-image:direct', (e) => {
      const process = new Process(
        e.file,
        e.kijshopCd,
        e.shopOrderId,
        e.orderId,
        '5',
        (p) => e.callback({ editableImage: p }),
        (p) => e.onCreatedEditableImage?.({ editableImage: p }),
        null,
        e.restore,
        e.path,
      );
      // enqueue する
      this.enqueue(process);
    });
    this.on('change-queue', () => {
      this.onChangeQueue();
    });
  }

  private onChangeQueue() {
    this.uiManager.emit('l->r:get-image-processor-status', {
      status: this.processQueue.length ? 'busy' : 'standby',
      total: this.processCounter,
      finish: this.processFinishCounter,
    });
    if (!this.processQueue.length) {
      console.log('実行する Process がありません。: ', this.processQueue);
      this.processCounter = 0;
      this.processFinishCounter = 0;
      return;
    }
    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    const startTime = window.performance.now();
    const firstProcess = this.processQueue[0];
    console.group(`[img-prc] (${this.processQueue.length}) process start ...`);
    this.do(firstProcess)
      .then(() => {
        if (!firstProcess.isDelete) {
          this.processFinishCounter += 1;
        }
        this.isBusy = false;
        const totalTime = window.performance.now() - startTime;
        console.log(`[imp-prc] finish (${totalTime} ms)`);
        console.groupEnd();
        this.dequeue();
      });
  }

  private static worker = new Worker();

  static resize(file: File) {
    const size = 1000;
    return new Promise<File>((resolve, reject) => {
      setTimeout(() => {
        console.log('file: ', file);
        new Compressor(
          file,
          {
            maxWidth: size,
            maxHeight: size,
            success(blob: Blob) {
              console.log('success');
              resolve(new File([blob], file.name, { type: file.type }));
            },
          },
        );
      });
    });
  }

  static fileToBase64(file: File): Promise<string> {
    return this.worker.fileToBase64(file);
  }

  static base64ToFile(base64: string, name: string, type: string): Promise<File> {
    return this.worker.base64ToFile(base64, name, type);
  }

  static loadExif(file: File) {
    return exifr.parse(file)
      .then(async (v) => {
        let orientation!: Orientation
        try {
          orientation = await getOrientation(file);
        } catch (error) {
          orientation = NaN
        }
        const exif = v ? (
          {
            createDate: v.CreateDate || v.ModifyDate || new Date(),
            colorSpace: v.ColorSpace,
            make: v.Make || '',
            model: v.Model || '',
            width: v.ExifImageWidth || 0,
            height: v.ExifImageHeight || 0,
            orientation,
          }
        ) : (
          {
            createDate: new Date(),
            colorSpace: '',
            make: '',
            model: '',
            width: 0,
            height: 0,
            orientation: 0,
          }
        );
        /* TODO 2024/3/26
         * 元々使用していたライブラリ(exifr)だと、
         * ColorSpaceに"1"のように文字列で入っていた場合検知ができないため
         * exifrで取得した情報が存在し、colorSpaceの情報がない時のみ、別のライブラリを使用してColorSpaceを取得
         * 文字列でそのまま入ってくるためnumber型にキャストして再代入する
         * 最終的には使用ライブラリを丸ごと移行したい
        */
        if (v && !v.colorSpace) {
          const colorSpace = (await ExifReader.load(file)).ColorSpace;
          if (colorSpace?.value && !isNaN(Number(colorSpace.value))) {
            exif.colorSpace = Number(colorSpace.value);
          }
        }
        return exif;
      });
  }

  static loadExifOrigin(file: File) {
    return exifr.parse(file);
  }

  static getImageSize(file: File): Promise<{ w: number, h: number}> {
    const url = URL.createObjectURL(file);
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onload = () => {
        const w = img.width;
        const h = img.height;
        URL.revokeObjectURL(url);
        resolve({ w, h });
      };
    });
  }
}

ImageProcessorManager.ins.initialize();
