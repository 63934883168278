import { Input } from '../../ui/input/input';
import { Select } from '../../ui/select/select';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { ordersActions, OrdersSliceState } from '../../../slices/orders-slice';
import { OrdersDataState } from '../../../slices/orders/orders-data-slice';
import { Checkbox } from '../../ui/input/checkbox';
import { Calendar } from '../../ui/input/calendar';
import { DescriptionPartInfoGetResponse } from '../../../models/api/front/description-info';
import { LogDecorator } from '@tenryu/log-decorator';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { Button } from '../../ui/button/button';
import iconHelp from '../../../assets/img/icon/help_icon.svg';
import { values } from 'lodash';
import { setName } from '../../../slices/orders/description-info/description-info';
import { HowToOrder, ImgType } from '../../dialog/unique/how-to-order';


export const DescriptionInfoOrder = () => {
  const dispatch = useDispatch();
  const {
    name,
    date,
    photographLocation,
    descriptionLocations,
    pageDataTypeRes,
    order,
    data,
    visibleCondition,
    debug,
  } = useAppSelector((state) => ({
    ...state.order.descriptionInfo,
    pageDataTypeRes: state.orderData.descriptionInfo.pageDataType.pageDataTypes,
    order: state.order,
    data: state.orderData,
    visibleCondition: state.createOrder.visibleCondition,
    debug: state.debug.debug,
  }), lodash.isEqual);
  // - Callback -
  // -- 名前入力 --
  const handlerChangeName = useCallback((v) => {
    dispatch(ordersActions.descriptionInfo.setName(v));
  }, []);
  const handlerBlurName = useCallback((v: string, label: string) => {
    const　value = delete4byte(v);
    let halfStr: string = '';
    let fullStr: string = '';
    for (let i: number = 0; i < value.length; i++) {
      const char = value.charCodeAt(i);
      if (0x20 <= char && char <= 0x7e) {
        halfStr += value[i];
      } else {
        fullStr += value[i];
      }
    }
    if (halfStr && fullStr) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '※半角と全角が混在して入力されています。',
          'このまま続行すると、文字情報がサンプルと異なる場合が有ります。',
          '',
          '・半角文字',
          `「${halfStr}」`,
          '',
          '・全角文字',
          `「${fullStr}」`,
          '',
          `商品情報ー商品詳細ー商品記載情報ー${label}`,
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
    }
  }, []);
  // -- 日付入力 --
  // const handlerChangeDate = useCallback((v) => {
  //   dispatch(ordersActions.descriptionInfo.setDate(v));
  // }, []);
  const handlerChangeDateKana = useCallback((v) => {
    dispatch(ordersActions.descriptionInfo.setDateKana(v));
  }, []);
  // -- 撮影場所 --
  const handlerChangePhotographLocation = useCallback((v) => {
    dispatch(ordersActions.descriptionInfo.setPhotographLocation(v));
  }, []);
  const handlerChangeComment = useCallback((v) => {
    dispatch(ordersActions.descriptionInfo.setComment(v));
  }, []);
  const handlerChangeAddress = useCallback((v) => {
    dispatch(ordersActions.descriptionInfo.setAddress(v));
  }, []);
  const handlerChangeDescriptionLocation = useCallback((v) => {
    const arr = [...(descriptionLocations || [])];
    const index = arr.findIndex((d) => d === v);
    if (index === -1) {
      arr.push(v);
    } else {
      arr.splice(index, 1);
    }
    dispatch(ordersActions.descriptionInfo.setDescriptionLocations(arr));
  }, [descriptionLocations]);
  // useEffect(() => {
  //   const pageDatatypeId = pageDataTypeRes[0]?.pageDataTypeId;
  //   const pageData = pageDataTypeRes.find((v) => v.pageDataTypeId === 'jptg380014')?.pageDataTypeId;
  //   if (pageDatatypeId && !descriptionLocations.length) {
  //     dispatch(ordersActions.descriptionInfo.setDescriptionLocations(pageData ? [pageDatatypeId, pageData] : [pageDatatypeId]));
  //   } else if (!descriptionLocations.find((v) => pageDataTypeRes.find((data) => data.pageDataTypeId === v))) {
  //     dispatch(ordersActions.descriptionInfo.setDescriptionLocations(pageData ? [pageDatatypeId, pageData] : [pageDatatypeId]));
  //   }
  // }, [pageDataTypeRes]);

  const handlerClickHelp = useCallback((type: ImgType) => {
    dispatch(dialogActions.push({
      title: '説明',
      element: <HowToOrder imgType={type}/>,
      closeBtn: true,
    }));
  }, []);

  const checkName = useCallback((e: any, v: string) => {
    if (e.code === 'Enter') {
      const data = delete4byte(v);
      dispatch(ordersActions.descriptionInfo.setName(data));
    }
  }, []);
  const checkLocation = useCallback((e: any, v: string) => {
    if (e.code === 'Enter') {
      const data = delete4byte(v);
      dispatch(ordersActions.descriptionInfo.setPhotographLocation(data));
    }
  }, []);
  const checkComment = useCallback((e: any, v: string) => {
    if (e.code === 'Enter') {
      const data = delete4byte(v);
      dispatch(ordersActions.descriptionInfo.setComment(data));
    }
  }, []);
  const delete4byte = useCallback((v: string): string => {
    const stringToArr = (str: string) => str.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g) || [];
    const base = stringToArr(v);
    let txt: string = '';
    base.forEach((vv) => {
      const char = vv.charCodeAt(1);
      if (!char) {
        txt += vv;
      }
    });
    return txt;
  }, []);

  // const display = displayItems(order, data);
  // const display = visibleCondition.getDescriptionInfoDisplay(order, data);
  visibleCondition.checkOrderDifference(order, data);
  const display = visibleCondition.descriptionInfoOrderDisplay;
  if (debug) {
    // console.group('商品記載情報');
    LogDecorator.group(`<magenta>商品記載情報</magenta>`);
    data.descriptionInfo.descriptionInfo.forEach((v, i) => {
      console.group(i);
      console.log('type: ', v.type);
      console.log('option: ', v.option);
      console.groupEnd();
    });
    console.groupEnd();
  }
  return (
    <div className="order_category">
      <div className="bottom_border_header order_category__header">
        商品記載情報
        <span className="order_category__header__caution">( ※半角と全角を混在して入力されますと文字情報がサンプルと異なる場合があります。)</span>
        <Button
          className="order_help order_category__header__tooltip"
          icon={<img src={iconHelp} alt='' />}
          onClick={() => handlerClickHelp('title-descriptionInfo')}
        />
      </div>

      {display.name ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            名前
          </div>
          <div className="top_label_ui__form">
            <Input
              value={name}
              onChange={(e) => handlerChangeName(e.target.value)}
              onKeyUp={(e) => checkName(e, name || '')}
              maxLength={30}
              onBlur={(e) => {
                handlerBlurName(e.target.value, '名前');
                dispatch(ordersActions.descriptionInfo.setName(delete4byte(e.target.value)));
              }}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('description-name')}
            />
          </div>
        </div>
      ) : (<></>)}
      {display.nameNenga ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            名前
          </div>
          <div className="top_label_ui__form">
            <Input
              value={name}
              onChange={(e) => handlerChangeName(e.target.value)}
              onKeyUp={(e) => checkName(e, name || '')}
              maxLength={300}
              onBlur={(e) => {
                handlerBlurName(e.target.value, '名前');
                dispatch(ordersActions.descriptionInfo.setName(delete4byte(e.target.value)));
              }}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('description-name2')}
            />
          </div>
        </div>
      ) : (<></>)}
      {display.nameRestrict ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            名前(半角英数)
          </div>
          <div className="top_label_ui__form">
            <Input
              value={name}
              onChange={(e) => handlerChangeName(e.target.value)}
              trimLength={20}
              onBlur={(e) => handlerBlurName(e.target.value, '名前(半角英数)')}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('description-name3')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.date ? (
        <>
          <Calendar
            getFormatDate={() => {
            }}
            callbackClickHelp={handlerClickHelp}
          />
        </>
      ) : (<></>)}

      {display.dateKana ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            ふりがな
          </div>
          <div className="top_label_ui__form">
            <Input
              value={date?.value || ''}
              onChange={(e) => handlerChangeDateKana(e.target.value)}
              maxLength={300}
              onBlur={(e) => handlerBlurName(e.target.value, 'ふりがな')}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('description-kana')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.location || display.locationHeight ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            撮影場所
          </div>
          <div className="top_label_ui__form">
            <Input
              value={photographLocation}
              onChange={(e) => handlerChangePhotographLocation(e.target.value)}
              onKeyUp={(e) => checkLocation(e, photographLocation || '')}
              maxLength={50}
              onBlur={(e) => {
                handlerBlurName(delete4byte(e.target.value), '撮影場所');
                dispatch(ordersActions.descriptionInfo.setPhotographLocation(delete4byte(e.target.value)));
              }}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('description-location')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.comment ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            コメント
          </div>
          <div className="top_label_ui__form">
            <Input
              value={photographLocation}
              onChange={(e) => handlerChangeComment(e.target.value)}
              onKeyUp={(e) => checkComment(e, photographLocation || '')}
              maxLength={200}
              onBlur={(e) => {
                handlerBlurName(delete4byte(e.target.value), 'コメント');
                dispatch(ordersActions.descriptionInfo.setComment(delete4byte(e.target.value)));
              }}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('description-comment')}
            />
          </div>
        </div>
      ) : (<></>)}
      {display.locationAddress ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            住所
          </div>
          <div className="top_label_ui__form">
            <Input
              value={photographLocation}
              onChange={(e) => handlerChangeAddress(e.target.value)}
              maxLength={300}
              onBlur={(e) => handlerBlurName(e.target.value, '住所')}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('description-address')}
            />
          </div>
        </div>
      ) : (<></>)}

      {/*{descriptionPartArr.length && (name || date.value || photographLocation) ? (*/}
      {/*  <div className="top_label_ui order_category__item">*/}
      {/*    <div className="top_label_ui__label">*/}
      {/*      記載箇所*/}
      {/*    </div>*/}
      {/*    <div className="top_label_ui__form">*/}
      {/*      <div className="item_description_place">*/}
      {/*        {descriptionPartArr.map((v, i) => (*/}
      {/*          <Checkbox*/}
      {/*            key={`description-part-checkbox_${v.name}_${i}`}*/}
      {/*            label={v.displayName}*/}
      {/*            checked={v.name === descriptionLocation}*/}
      {/*            onClick={() => handlerChangeDescriptionLocation(v.name)}*/}
      {/*          />*/}
      {/*        ))}*/}
      {/*      </div>*/}
      {/*      <Button className="order_help" icon={<img src={iconHelp} />} onClick={() => {}} />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*) : (<></>)}*/}
      {pageDataTypeRes.length && (name || date?.value || photographLocation) ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            記載箇所
          </div>
          <div className="top_label_ui__form">
            <div className="item_description_place">
              {pageDataTypeRes.map((v, i) => (
                <Checkbox
                  key={`description-part-checkbox_${v.pageDataTypeId}_${i}`}
                  label={v.pageDataTypeDisplayName}
                  checked={Boolean(descriptionLocations?.find((d) => d === v.pageDataTypeId))}
                  onClick={() => handlerChangeDescriptionLocation(v.pageDataTypeId)}
                />
              ))}
            </div>
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('description-place')}
            />
          </div>
        </div>
      ) : (<></>)}

    </div>
  );
};
