import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../app/store';
import { ResponseCallback } from '../api/response-callback';
import { authActions } from './api/auth-actions';
import { xmlVersionActions } from './api/xml-version-actions';
import { orderActions } from './api/order-actions';
import { ApiBase } from '../api/api-base';
import { ResponseBase } from '../api/response-base';
import { dialogActions } from '../components/dialog/slice/dialog-slice';
import { templateActions } from './api/template-actions';
import { UuidGenerator } from '../utilities/uuid-generator';
import { push } from 'connected-react-router';
import { RoutingPath } from '../routes/routing-path';
import { ApiBaseBack } from '../api/back/api-base-back';
import { ApiBaseFront } from '../api/front/api-base-front';

export type CommonSliceState = {
  connecting: boolean,
  connectingIdArr: string[],
  nameArr: { id: string, name: string }[],
};

const initialState: CommonSliceState = {
  connecting: false,
  connectingIdArr: [],
  nameArr: [],
};

export const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    addConnect: (state, action: PayloadAction<{ id: string, name?: string }>) => {
      if (!state.connectingIdArr.find((id) => id === action.payload.id)) {
        state.connectingIdArr.push(action.payload.id);
        if (!state.connecting) {
          state.connecting = true;
        }
      }
      if (action.payload.name) {
        const arr = [...state.nameArr];
        const index = arr.findIndex((v) => v.name === action.payload.name);
        if (index !== -1) {
          arr[index].id = action.payload.id;
        } else {
          arr.push({ id: action.payload.id, name: action.payload.name });
        }
        state.nameArr = arr;
      }
    },
    removeConnect: (state, action: PayloadAction<string>) => {
      const index = state.connectingIdArr.findIndex((id) => id === action.payload);
      if (index !== -1) {
        if (state.connecting && state.connectingIdArr.length === 1) {
          state.connecting = false;
        }
        state.connectingIdArr.splice(index, 1);
      }
    },
  },
});

const run = (
  api: ApiBase,
  /*
   * onSuccess    : データ無しを含む正常系レスポンス
   * onGetData    : データ無しは含まない正常系レスポンス
   * onNoData     : (bodyの)データ無しの正常系レスポンス
   * onError      : 正常系に当てはまらないエラーケース(他の処理と重複)
   * onBasicError : パラメータ起因などのAPI依存のエラー
   * onSystemError: ネットワークエラーなど不測のエラー
   * */
  callback?: {
    onGetData?: (res: any) => void,
    onNoData?: (res: any) => void,
    onError?: (res: any) => void,
  } & Partial<ResponseCallback>,
  options?: {
    apiName?: string,
    ignoreSystemError?: boolean,
    ignoreTimeout?: boolean,
  },
): AppThunk => async (dispatch, getState) => {
  const id = UuidGenerator.create();
  dispatch(apiActions.addConnect({ id, name: options?.apiName }));
  api
    .do()
    .then((res) => {
      if ((api.responseType === 'BLOB' && (res as Blob)?.type && (res as Blob)?.type !== 'json/application') || ApiBase.isSuccess(res)) {
        if (options?.apiName && getState().api.nameArr.find((v) => v.name === options.apiName && v.id === id)) {
          callback?.onSuccess?.(res);
          if (api.responseType === 'JSON') {
            if (res?.body?.data) {
              callback?.onGetData?.(res);
            } else {
              callback?.onNoData?.(res);
            }
          }
        } else {
          callback?.onSuccess?.(res);
          if (api.responseType === 'JSON') {
            if (res?.body?.data) {
              callback?.onGetData?.(res);
            } else {
              callback?.onNoData?.(res);
            }
          }
        }
        // callback?.onSuccess?.(res);
        // if (res?.body?.data) {
        //   callback?.onGetData?.(res);
        // } else {
        //   callback?.onNoData?.(res);
        // }
      } else {
        callback?.onError?.(res);
        if (ApiBase.isIrregularError(res)) {
          callback?.onSystemError?.(res);
          if (!options?.ignoreSystemError) {
            dispatch(dialogActions.pushSystemError());
          }
        } else if (ApiBase.isSessionTimeout(res)) {
          if (!options?.ignoreTimeout) {
            if (ApiBase.isValidTimeout && ApiBase.sessionTimeout === 0) {
              dispatch(dialogActions.popAll());
              ApiBase.sessionTimeout++;
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: [
                  'セッションの有効期限が切れました。',
                  '再度ログインしてください。',
                ],
                buttons: [
                  {
                    label: 'OK',
                    callback: () => {
                      dispatch(dialogActions.pop());
                      // if (getState().storage.loginMode === 'ADMIN') {
                        dispatch(push(RoutingPath.login));
                      // } else {
                      //   dispatch(push(RoutingPath.staffLogin));
                      // }
                      ApiBaseBack.session = '';
                      ApiBaseFront.session = '';
                      ApiBase.sessionTimeout = 0;
                    },
                  },
                ],
              }));
            }
          }
        } else if (ApiBase.isTimeout(res)) {
          if (ApiBase.timeout === 0) {
            ApiBase.timeout++;
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: [
                'タイムアウトしました',
              ],
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                    ApiBase.timeout = 0;
                  },
                },
              ],
            }));
          }
        } else {
          callback?.onBasicError?.(res);
          if (res.message === 'セッションIDが存在しません。' ||
            res.messages?.find((v: string) => v === 'セッションIDが存在しません')) {
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: [
                'セッションIDが存在しません。',
                '再度ログインしてください。',
              ],
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.popAll());
                    if (getState().storage.loginMode === 'ADMIN') {
                      dispatch(push(RoutingPath.login));
                    } else {
                      dispatch(push(RoutingPath.staffLogin));
                    }
                    ApiBaseBack.session = '';
                    ApiBaseFront.session = '';
                    ApiBase.sessionTimeout = 0;
                  },
                },
              ],
            }));
          }
        }
      }
      dispatch(apiActions.removeConnect(id));
    })
    .catch((e) => {
      callback?.onError?.(e);
      callback?.onSystemError?.(e);
      if (!options?.ignoreSystemError) {
        dispatch(dialogActions.pushSystemError());
      }
      dispatch(apiActions.removeConnect(id));
    });
};

const runAll = (
  apiArr: ApiBase[],
  /*
   * onSuccess    : データ無しを含む正常系レスポンス
   * onGetData    : データ無しは含まない正常系レスポンス
   * onNoData     : (bodyの)データ無しの正常系レスポンス
   * onError      : 正常系に当てはまらないエラーケース(他の処理と重複)
   * onBasicError : パラメータ起因などのAPI依存のエラー
   * onSystemError: ネットワークエラーなど不測のエラー
   * */
  callback?: {
    onGetData?: (res: any) => void,
    onNoData?: (res: any) => void,
    onError?: (res: any) => void,
  } & Partial<ResponseCallback>,
  ignoreSystemError?: boolean,
  ignoreTimeout?: boolean,
): AppThunk => async (dispatch) => {
  const arr = [...apiArr].map((api) => new Promise<any>((resolve, reject) => {
    dispatch(run(
      api,
      {
        onSuccess: resolve,
        onError: reject,
      },
      {
        ignoreSystemError,
        ignoreTimeout,
      },
    ));
  }));
  Promise.all(arr)
    .then((res) => {
      callback?.onSuccess?.(res);
    })
    .catch((e) => {
      callback?.onError?.(e);
    });
};

const asyncActions = {
  ...authActions,
  ...xmlVersionActions,
  ...orderActions,
  ...templateActions,
  // API実行（レスポンス判定まで）
  run,
  runAll,
};

export const apiActions = Object.assign(apiSlice.actions, asyncActions);
export const apiReducer = apiSlice.reducer;
