import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { DateFormatter } from '../../../utilities/date-formatter';

export type StaffEditParams = {
  id: string
  kijshopCd: string,
  mailAddress: string,
  name: string,
}

type Props = {
  kijshopCd: string,
  data: JSX.Element[] | StaffEditProps,
  handlerClickUnlock: (id: string) => void,
  handlerClickResetPassword: (id: string) => void,
  handlerClickChangePrePassword: (id: string) => void,
  handlerClickInvalid: (id: string) => void,
  handlerClickUpdateStaff: (props: StaffEditParams) => void,
  handlerClickClose: () => void,
  isDoubleClick: boolean,
}

export type StaffEditProps = {
  name: string,
  status: string,
  mailAddress: string,
  staffId: string,
  lastLoginDate: string,
  loginFailureCount: number,
  lockDate: string,
  id: string,
}

type InputErrorItem = 'name' | 'mailAddress' | 'none';

type StaffData = {
  name: string,
  status: string,
  mailAddress: string,
  staffId: string,
  lastLoginDate: string,
  loginFailureCount: number,
  lockDate: string,
  id: string,
}

const PASSWORD_RESET = 'パスワードリセット';
const PASSWORD_CHANGE = '仮パスワードの変更';

export const EditStaff = (props: Props) => {
  const {
    kijshopCd,
    data,
    handlerClickUnlock,
    handlerClickResetPassword,
    handlerClickChangePrePassword,
    handlerClickInvalid,
    handlerClickUpdateStaff,
    handlerClickClose,
    isDoubleClick,
  } = props;
  // - Handlers -
  const convertStaffData = useCallback((data: JSX.Element[] | StaffEditProps, isDoubleClick: boolean): StaffData => {
    if (isDoubleClick) {
      if (!Array.isArray(data)) throw new Error('data is not JSX.Element[]');
      const staffId = data[0].props['data-status'];
      const name = data[1].props['data-status'];
      const status = data[2].props['data-status'];
      const mailAddress = data[3].props['data-status'];
      const lastLoginDate = data[4].props['data-status'];
      const loginFailureCount = data[5].props['data-status'];
      const lockDate = data[6].props['data-status'];
      const id = data[8].props['data-status'];
      return {
        staffId,
        name,
        status,
        mailAddress,
        lastLoginDate,
        loginFailureCount: Number(loginFailureCount),
        lockDate,
        id,
      };
    } else {
      if (Array.isArray(data)) throw new Error('data is JSX.Element[]');
      return {
        staffId: data.staffId,
        name: data.name,
        status: data.status,
        mailAddress: data.mailAddress,
        lastLoginDate: data.lastLoginDate,
        loginFailureCount: Number(data.loginFailureCount),
        lockDate: data.lockDate,
        id: data.id,
      };
    }
  }, []);
  const staffData = convertStaffData(data, isDoubleClick);
  // - State -
  const [mailAddress, setMailAddress] = useState(staffData.mailAddress);
  const [name, setName] = useState(staffData.name);
  // -- 氏名がバリデーションにかかっているか --
  const [isNameError, setIsNameError] = useState(false);
  // -- メールアドレスがバリデーションにかかっているか --
  const [isMailError, setIsMailError] = useState(false);
  // -- ツールチップ表示するもの --
  const [tooltipItem, setTooltipItem] = useState<InputErrorItem>('none');
  // -- 保存ボタンバリデーションフラグ --
  const [isDisabledSave, setIsDisabledSave] = useState(false);
  // - ref -
  // - メールアドレス規表現 -
  const mailRegExp = useRef(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).current;
  // - callback -
  // - バリデーションチェック -
  const checkInputValidation = useCallback((type: InputErrorItem, inputItem: string): boolean  => {
    let result: boolean;
    switch (type) {
      case 'name':
        if (inputItem && inputItem.length < 255) {
          result = true;
        } else {
          result = false;
        }
        break;
      case 'mailAddress':
        if (inputItem && (inputItem.match(mailRegExp))) {
          result = true;
        } else {
          result = false
        }
        break;
      default:
        result = true;
        break;
    }
    return result;
  }, []);
  const handlerChangeMailAddress = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setMailAddress(e.target.value);
  }, [mailAddress]);
  const handlerChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setIsNameError(!checkInputValidation('name', e.target.value))
  }, [name]);
  const handlerMouseOver = useCallback((type: InputErrorItem) => {
    setTooltipItem(type);
  }, []);
  // - effect -
  useEffect(() => {
    setIsDisabledSave(checkInputValidation('name', name) &&
    checkInputValidation('mailAddress', mailAddress))
  }, [name, mailAddress]);

  return (
    <div className="dialog_contents staff">
      <div className="staff__forms">
        {staffData.staffId !== staffData.mailAddress && (
          <div className="label_input">
            <div className="label_input__label">スタッフID</div>
            <Input
              value={staffData.staffId}
              disabled
            />
          </div>
        )}
        <div className="label_input">
          <div className="label_input__label">メールアドレス</div>
          <Input
            value={mailAddress}
            disabled={staffData.staffId === staffData.mailAddress}
            onChange={handlerChangeMailAddress}
            onMouseOver={() => handlerMouseOver('mailAddress')}
            onMouseLeave={() => handlerMouseOver('mailAddress')}
            error={isMailError}
            tooltip={isMailError && tooltipItem === 'mailAddress' ? { messages: ['例: ppm@example.com'] } : undefined}
            onBlur={() => setIsMailError(!checkInputValidation('mailAddress', mailAddress))}
          />
        </div>
        <div className="label_input">
          <div className="label_input__label">名前</div>
          <Input
            value={name}
            onChange={handlerChangeName}
            onMouseOver={() => handlerMouseOver('name')}
            onMouseLeave={() => handlerMouseOver('none')}
            error={isNameError}
            tooltip={isNameError && tooltipItem === 'name' ? { messages: ['255文字以下'] } : undefined}
          />
        </div>
        <div className="label_button">
          <div>アカウントロックの解除</div>
          <Button
            label="実行"
            onClick={() => handlerClickUnlock(staffData.id)}
            disabled={!DateFormatter.getDiffTime(staffData.lockDate)}
          />
        </div>
        <div className="label_button">
          <div>{staffData.status === '本登録' ? PASSWORD_RESET : PASSWORD_CHANGE}</div>
          <Button
            label={staffData.status === '本登録' ? '送信' : '実行'}
            onClick={() => {
              if (staffData.status === '本登録') {
                handlerClickResetPassword(staffData.staffId);
              } else {
                handlerClickChangePrePassword(staffData.id);
              }
            }}
          />
        </div>
        <div className="label_button">
          <div>アカウントの無効化</div>
          <Button
            label="実行"
            color="danger"
            onClick={() => handlerClickInvalid(staffData.id)}
            disabled={staffData.status === '無効'}
          />
        </div>
      </div>
      <div className="dialog_contents__footer">
        <Button
          label="キャンセル"
          onClick={handlerClickClose}
        />
        <Button
          label="保存"
          onClick={() => {
            handlerClickUpdateStaff({
              id: staffData.id,
              kijshopCd: kijshopCd,
              mailAddress: mailAddress,
              name: name,
            })
          }}
          disabled={!isDisabledSave}
        />
      </div>
    </div>
  );
};
