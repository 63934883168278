import { Button } from '../button/button';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { apiActions } from '../../../slices/api-slice';
import { FavoriteBrandConfig } from '../../dialog/unique/favorite-brand-config';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import './config-header-items.scss';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';
import { Image } from '../image/image';
import iconUser from '../../../assets/img/icon/user.svg';
import iconSetting from '../../../assets/img/icon/setting.svg';
import iconFavorite from '../../../assets/img/icon/favorite.svg';
import iconStaffManagement from '../../../assets/img/icon/staff-management.svg';
import iconLogout from '../../../assets/img/icon/logout.svg';


type ConfigHeaderItemsProps = {
  fadeIn?: boolean;
  className?: string;
}

export const ConfigHeaderItems = (props: ConfigHeaderItemsProps) => {
  const { fadeIn = true, className } = props;
  // - Hooks -
  const { kijshopCd } = useParams<PathParams>();
  const dispatch = useDispatch();
  const { isStaffManagementAuth, staffInfo } = useAppSelector((state) => ({
    isStaffManagementAuth: state.common.isStaffManagementAuth,
    staffInfo: state.common.staffInfo,
  }), lodash.isEqual);
  // - Callback -
  // -- システム設定ボタン --
  const handlerClickSystemConfig = useCallback(() => {
    dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })));
  }, []);
  // -- システム設定ボタン --
  const handlerClickStaff = useCallback(() => {
    dispatch(push(RoutingPath.cnv.staff({ kijshopCd })));
  }, []);
  // // -- バックアップボタン --
  // const handlerClickBackup = useCallback(() => {}, []);
  // -- お気に入り商品設定ボタン --
  const handlerClickFavoriteBrandConfig = useCallback(() => {
    dispatch(dialogActions.push({
      title: 'お気に入り商品設定',
      element: <FavoriteBrandConfig kijshopCd={kijshopCd} />,
      className:"favorite_brand_config__ancestor"
    }));
  }, []);
  // -- ログアウトボタン --
  const handlerClickLogout = useCallback(() => {
    if (staffInfo?.staffId) {
      dispatch(apiActions.staffLogout({ kijshopCd }));
      return;
    }
    dispatch(apiActions.logout({ kijshopCd }));
  }, []);

  const getUserName = useCallback(() => {
    if (!staffInfo) return '';
    if (staffInfo.staffId) {
      if (!staffInfo.name) return 'スタッフ';
      return staffInfo.name;
    }
    return '店舗管理者';
  }, []);

  return (
    <div className={`config_header_items${fadeIn ? ' fade_in' : ''} ${className || ''}`}>
      <div className="inner">
        <div className="user_name">
          <Button
            label={getUserName()}
            color="light"
            icon={<Image
              src={iconUser}
              alt=""
            />}
            size={'lg'}
            title={getUserName()}
          />
        </div>
        <div className="divider">
        </div>
      <Button
        label="システム設定"
        onClick={handlerClickSystemConfig}
        icon={<Image src={iconSetting} alt='' />}
      />
      {/* <Button
        label="バックアップ"
        onClick={handlerClickBackup}
      /> */}
      <Button
        label="お気に入り商品設定"
        onClick={handlerClickFavoriteBrandConfig}
        icon={<Image src={iconFavorite} alt='' />}
      />
        {isStaffManagementAuth && !staffInfo?.staffId && (
          <Button
            label="スタッフ管理"
            onClick={handlerClickStaff}
            icon={<Image
              src={iconStaffManagement}
              alt=""
            />}
          />
        )}
      <Button
        label="ログアウト"
        onClick={handlerClickLogout}
        icon={<Image src={iconLogout} alt='' />}
      />
      </div>
    </div>
  );
};
