import { EditableImage } from '../image-edit/editable-image';
import { ImageProcessorManager } from './image-processor.manager';
import { CjBitmap } from '../../model/cj-factory';
import { CjTool } from '../../../utilities/cj-tool';
import { CJ_DPOBJ_NAME } from '../../model/cj-factory/cj-obj-name.collection';
import { HashGenerator } from '../../../utilities/hash-generator';
import { ApiBaseFront } from '../../../api/front/api-base-front';
import { ApiImagesGetOne } from '../../../api/front/images/api-images';
import { store } from '../../../app/store';
import { apiActions } from '../../../slices/api-slice';
import { Orientation } from 'get-orientation/browser';

const dummyExif = {
  colorSpace: '',
  model: '',
  make: '',
  createDate: '',
  orientation: 0,
};

export class Process {
  constructor(
    public original: File | null,
    public kijShopCd: string,
    public shopOrderId: string,
    public orderId: string | null,
    public kind: string, // 完成画像:1, アップロード画像:6
    public onUploaded: (editableImage: EditableImage, sameImage?: boolean) => void = () => {
    },
    public onCreatedEditableImage: (editableImage: EditableImage, sameImage?: boolean) => void = () => {
    },
    public additionalProps: {
      originalName: string,
      name: string,
      exif: { colorSpace: any; model: any; orientation: any; make: any; createDate: any },
      width: number,
      height: number,
      thumbnailUrl: string,
      hash: string,
    } | null,
    public restore: boolean = false,
    public path: string = '',
    public selectId: string = '',
    public isUseCheck: boolean = false,
    public hashData?: { create?: boolean, hash?: string, checkList?: { path: string, hash: string, orientation?: Orientation }[], selectIdList?: { path: string, selectID: string }[] },
    public samePushIgnore?: boolean,
    public isDelete?: boolean,
    public onError?: () => void,
  ) {
  }

  async all() {
    const original = await this.makeOriginal();
    // NOTE : オリジナルの Base64 はメモリを圧迫するので保持しない
    // const originalBase64 = await this.makeOriginalBase64();
    const thumbnail = await this.makeThumbnail();
    const thumbnailBase64 = await this.makeThumbnailBase64(thumbnail);
    console.log('- after make thumbnail base64 -');
    const cjImage = await this.makeCjImage(thumbnailBase64);
    const exif = original ? await ImageProcessorManager.loadExif(original) : (this.additionalProps?.exif || dummyExif);
    const hash = (this.hashData?.create && original) ? await HashGenerator.generate(original) : this.hashData?.hash;
    const size = await this.makeWidthHeight();
    const editableImage = new EditableImage({
      name: original ? original.name : (this.additionalProps?.originalName || 'NoName'),
      kijshopCd: this.kijShopCd,
      shopOrderId: this.shopOrderId,
      orderId: this.orderId,
      selectId: this.selectId,
      original,
      kind: this.kind,
      // NOTE : オリジナルの Base64 はメモリを圧迫するので保持しない
      originalBase64: undefined,
      thumbnail,
      thumbnailBase64,
      editable: cjImage,
      exif: { ...exif, createDate: new Date(exif.createDate) },
      height: String(cjImage.image.height),
      width: String(cjImage.image.width),
      hash,
      realWidth: String(size.w),
      realHeight: String(size.h),
    });
    if (this.restore) {
      editableImage.flags.uploaded = true;
      editableImage.path = this.path;

    }
    if (this.selectId) {
      editableImage.selectId = this.selectId;
    }
    if (this.isUseCheck) {
      editableImage.flags.used = true;
    }
    if (isNaN(exif.orientation)) {
      exif.orientation = 0;
    }
    // this.callback(editableImage);
    return editableImage;
  }

  private makeOriginal() {
    console.log('[img-prc] make original ...');
    return Promise.resolve(this.original);
  }

  private async makeThumbnail() {
    console.log('[img-prc] make thumbnail ...');
    if (this.original) {
      return ImageProcessorManager.resize(this.original);
    }
    if (!this.additionalProps) {
      throw new Error('additionalProps を設定してください !!');
    }
    const blob = await new Promise<Blob>((resolve) => {
      if (!this.additionalProps) {
        throw new Error('additionalProps を設定してください !!');
      }
      store.dispatch(apiActions.run(
        new ApiImagesGetOne({
          kijshopCd: this.kijShopCd,
          path: `${this.additionalProps.thumbnailUrl}/${this.additionalProps.name}`,
        }),
        {
          onSuccess: (blob: Blob) => {
            resolve(blob);
          },
          onError: (blob: Blob) => {
            resolve(blob);
          }
        },
      ));
    });
    return new File(
      [blob],
      this.additionalProps.originalName,
      {
        type: 'image/jpg',
      },
    );
  }

  private makeThumbnailBase64(thumbnail: File) {
    console.log('[img-prc] make thumbnail base64 ...');
    return ImageProcessorManager.fileToBase64(thumbnail);
  }

  private makeCjImage(thumbnailUrl: string) {
    console.log('[img-prc] make create.js image ...');
    return new Promise<CjBitmap>((resolve) => {
      const image = new CjBitmap(thumbnailUrl);
      CjTool.checkLoad(image).then((response) => {
        response.name = CJ_DPOBJ_NAME.image;
        resolve(response);
      });
    });
  }

  private makeWidthHeight() {
    console.log('[img-prc] make width/height ...');
    if (this.original) {
      return ImageProcessorManager.getImageSize(this.original);
    }
    if (!this.additionalProps) {
      throw new Error('additionalProps を設定してください !!');
    }
    return { w: this.additionalProps.width, h: this.additionalProps.height };
  }
}
